#map {
    z-index: 5;
    height: 440px;
    body.page-template-tpl-tours &,
    body.page-template-tpl-rentals & {
        height: 540px;
    }    
}



.locations-map {
    position: relative;
    .loading {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: hsla(0,0%,100%,.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1
    }
}

.location-intro {
    #map {
        height: 350px;
    }
}

.embed-responsive iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.locations-map .embed-responsive:before {
    padding-top: 30%;
}

@include media-breakpoint-down(md) {
    .map-wrap {
        display: flex;
        flex-direction: column-reverse;
        padding: 67px 0 0
    }
}

.map-extra-wrap {
    position: relative
}

.pac-container {
    box-shadow: 0 2px 2px rgba(0,0,0,0.3);
}

.no-map-results {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: hsla(0,0%,100%,.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1
}

.gm-style-iw-parent:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 7.2px;
    transform: translateX(-50%);
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid #fff
}

.gm-style-iw-d {
    overflow: hidden !important
}

.gm-style-iw {
    padding: 0 !important
}

.info-window-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    min-width: 250px;
    .info-window-body {
        color: $white;
        padding: 11px 10px;
        background: #3D3836;
        border-radius: 5px 5px 0 0;
        .title {
            font-size: 16px;
            font-family: $headings-font-family;
        }
        .address {
            font-size: 13px;
            font-weight: 700;
        }
    }
    .info-window-footer {
        background: $white;
        color: #3D3836;
        font-size: 13px;
        font-weight: 700;
        padding: 10px;
        border-radius: 0 0 5px 5px;
        a {
            color: #3D3836;
            &:hover {
                
            }
        }
    }
    &.talio-store {
        .info-window-body {
            background: $turquoise;
        }
    }
}



// Search

#find-location {
    font-size: 16px;
}

#find-location .search-row {
    text-align: center;
    position: relative;
    z-index: 10;
    @include media-breakpoint-down(md) {
        display: none;
    }
}

@include media-breakpoint-up(md) {
    #find-location .search-row {
        top:-30px;
    }
}

#find-location .search-row .search-input-wrap {
    position: relative;
}

@include media-breakpoint-up(md) {
    #find-location .search-row .search-input-wrap {
        max-width: 510px;
        display: inline-block;
        width: 100%
    }
}

#find-location .search-row .search-input-wrap:before {
    content: '';
    left: 22px;
    height: 18px;
    width: 1px;
    background-color: $charcoal
}

#find-location .search-row .search-input-wrap .fas,
#find-location .search-row .search-input-wrap:before {
    top: 50%;
    position: absolute;
    transform: translateY(-50%)
}

#find-location .search-row .search-input-wrap .fas {
    right: 21px;
    font-size: 27px;
    color: #59595c
}

#find-location .search-row input {
    padding: 25px 60px 25px 30px;
    font-size: 18px;
    background: #e8e8e8;
    border: none;
    width: 100%;
    text-indent: 8px;
    outline: none;
}

#find-location .search-row input {
    @include placeholder {
        color: $text-muted
    }
}

#find-location .form-row {
    display: flex;
    justify-content: center
}

#find-location .form-group {
    width: 100%;
    max-width: 300px;
    padding: 20px 20px 0
}
