.woocommerce-info {
    text-align: center;
    margin-top: 20px;
}

.section-archive-filter {
    background: #f4f4f4;    
    body.filter-fixed & {        
        padding-bottom: 90px;
        .filters {
            width: 100%;
            position: fixed;
            z-index: 999;
            top: 47px;
            left: 0
        }        
    }
    .filters {
        text-align: center;
        margin-bottom: 0;
        width: 100%;
        li.filter {
            display: inline-block;
            padding: 15px 25px;
            position: relative;
            text-transform: uppercase;
            color: #58595b;
            font-size: 16px;
            transition: color .3s;
            cursor: pointer;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                top: calc(100% - 4px);
                height: 0;
                transition: height .3s;
                background: #ee6845
            }
            &:hover {
                color: $turquoise;
            }
            &.active {
                font-weight: 700;
                color: $turquoise;
            }
            &.active:before,
            &:hover:before {
                height: 4px
            }
        }
        &.bg-turquoise {
            li {
                color: $white;
            }
        }
    }
}

.has-products {
}

ul.products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    padding-left: 0px;
    margin: 0 auto;
}

ul.product-categories {
    @extend .products;
}

ul.products .onsale {
    color: $white;
    background-color: $turquoise;
    min-height: auto;
    min-width: auto;
    font-size: 16px;
    font-weight: 600;
    width: auto;
    height: 46px;
    line-height: 45px;
    margin: 0;
    padding: 0 19px;
    left: auto;
    right: 0;
    top: 0;
    border-radius: 0;
    position: absolute;
    text-transform: none;
}

ul.products .onsale img {
    width: 84%;
    margin: auto
}

ul.products li.product,
ul.products li.product-category {
    width: calc(25% - 40px);
    position: relative;
    text-align: center;
    margin: 0 20px 40px;
}

@include media-breakpoint-down(lg) {
    ul.products li.product,
    ul.products li.product-category {
        width: calc(25% - 20px);
        margin: 0 10px 40px;
        //width: calc(33.33333% - 40px);
    }
}

@include media-breakpoint-down(md) {
    ul.products li.product,
    ul.products li.product-category {
        width: calc(50% - 40px);
        margin: 0 20px 40px;
    }
}

@include media-breakpoint-down(sm) {
    ul.products li.product,
    ul.products li.product-category {
        width: 100%;
        margin: 0 0 20px;
    }
}

ul.products li.product-category a,
ul.products li.product a.woocommerce-loop-product__link {
    padding: 50px 20px;
    display: flex;
    border: 1px solid $border-color;
    transition: .3s;
    position: relative;
    height: 100%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
}

ul.products li.product-category a:after,
ul.products li.product a.woocommerce-loop-product__link:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,.3);
    transition: .3s;
    opacity: 0;
}

ul.products li.product-category a img,
ul.products li.product a.woocommerce-loop-product__link img {
    display: block;
    max-width: 100%;
    height: auto;
}

ul.products li.product-category a .woocommerce-loop-category__title .count,
ul.products li.product a.woocommerce-loop-product__link .woocommerce-loop-product__title .count {
    background: none;
}

ul.products li.product-category a .woocommerce-loop-category__title,
ul.products li.product a.woocommerce-loop-product__link .woocommerce-loop-product__title {
    margin-top: 30px;
    font-size: 26px
}

ul.products li.product a.woocommerce-loop-product__link .price {
    margin-top: auto;
    font-size: 20px;
    font-family: 'GreycliffCF-Bold';
    del {
        color: $muddy-green;
        .amount {
            color: $muddy-green;
        }
    }
    ins {
        text-decoration: none;
    }
    .woocommerce-price-prefix,
    .woocommerce-price-suffix {
        color: $logo-grey;
    }
}

ul.products li.product a.btn {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 40px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    transition: .3s;
    opacity: 0;
    text-align: center;
    white-space: nowrap;
}

ul.products li.product a.btn:hover {
}

ul.products li.product-category a:hover,
ul.products li.product a.woocommerce-loop-product__link:hover {
    text-decoration: none;
}

ul.products li.product-category:hover a:after,
ul.products li.product:hover a.woocommerce-loop-product__link:after {
    opacity: 1
}

ul.products li.product:hover a.btn {
    opacity: 1;
    transform: translate(-50%,-50%)
}


.related {
    padding-top: 80px;
    padding-bottom: 100px;
    .products {
        @include media-breakpoint-up(md) {
            margin-left: -20px;
            margin-right: -20px;
        }
        @include media-breakpoint-only(lg) {
            margin-left: -10px;
            margin-right: -10px;
        }
        li {
            margin-bottom: 0 !important;
        }
    }
}

.section-archive-info {
    background: $turquoise;
    color: $white;
    padding-top: 125px;
    padding-bottom: 30px;
    overflow: visible;
    margin-bottom: 50px;
    @include media-breakpoint-up(lg) {

    }
}

.section-archive-info .main-ttl-1 {
    margin-bottom: 20px;
    text-align: left;
}
.section-archive-info p {
    line-height: 1.3;
}
.section-archive-info p:last-child {
    margin-bottom: 0px;
}
.section-archive-info .container .col-lg-5 {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
}
.section-archive-info .container .col-lg-5 .image {
    width: 100%;
    padding-top: 112%;
    margin-bottom: -106px;
}
.section-archive-info .container .col-6:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
}


@include media-breakpoint-down(md) {
    .section-archive-info .container .wrap-img {
        position:absolute;
        top: 100%;
        max-width: 274px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%)
    }
}

.section-archive-info em,
.section-archive-info i {
    font-style: italic
}

.section-archive-info b,
.section-archive-info strong {
    font-weight: 700
}




.term-accessories .section-archive-info .container .col-6 {
    padding: 0;
}

@include media-breakpoint-down(lg) {
    .term-accessories .section-archive-info .container .col-6:first-child {
        width: 56%;
        padding-left: 70px;
    }

    .term-accessories .section-archive-info .container .col-6:last-child {
        width: 44%;
        padding-left: 60px;
        padding-right: 60px;
    }
}

.term-accessories .section-archive-info .container .col-6 .image {
    width: 112%;
    padding-top: 88%;
    margin-bottom: -100px;
    margin-left: -100px;
    margin-top: 22px
}

@include media-breakpoint-down(lg) {
    .term-accessories .section-archive-info .container .col-6 .image {
        width:100%;
        padding-top: 80%;
        margin-bottom: -101px;
        margin-left: -10px;
        margin-top: 22px
    }
}
