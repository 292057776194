.footer {
    position: relative;
    &:before {

    }
    &:after {

    }
    .footer-block {
        margin-bottom: 40px;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
        ul li {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: lighten($font-color-base, 5%);
                font-family: 'GreycliffCF-Light';
            }
        }
        &-title {
            font-size: 20px;
            color: $turquoise;
            text-transform: uppercase;
        }
        .custom-checkbox {
            label {
                font-size: 90%;
                font-family: 'GreycliffCF-Light';
                color: lighten($font-color-base, 5%);
                a {
                    color: $white;
                }
            }
        }
    }    
    &-copyright {
        flex: 1;
        font-size: 13px;
    }
    &-gdpr {
        a {
            font-size: 13px;
            position: relative;
            &:after {
                content: '/';
                margin: 0 5px;
                color: $muddy-green;
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
}

//--------------

.instagram-reel {
    .instagram-reel-items {
        display: flex;
        flex-wrap: wrap;
        .instagram-reel-item {
            display: block;
            overflow: hidden;
            position: relative;
            width: calc(100% / 10);
            &:before {
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: .85;
                position: absolute;
                pointer-events: none;
                transition: all 0.3s ease;
                background-color: rgba($charcoal,.85);
            }
            &:hover {
                &:before {
                    opacity: .5;
                    background-color: rgba($charcoal,.65);
                }
            } 
            .instagram-reel-item-counters {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all 0.3s ease;
                box-sizing: border-box;
                margin-top: -100px;
                opacity: 0;
                color: #fff;
                display: block;
                width: 100%;
                text-align: center;
                font-size: 11px;
            }
        }
    }
    .instagram-reel-title-wrap {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        position: absolute;
        text-align: center;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .instagram-reel-title {
            margin-bottom: 0;
            a {
                color: $off-white;
                @include transition;
                &:hover {
                    color: $white;
                    text-decoration: none;
                }
            }
        }
    }
}

//--------------

.stay-connected {
    text-align: center;
    &-links {
        padding: 0 0 20px;
        a {
            width: 52px;
            height: 52px;
            margin: 0 3px;
            color: $white;
            line-height: 52px;
            text-align: center;
            display: inline-block;
            @include transition;
            @include font-size(32);
            @include hover {
                opacity: .75;
                color: $charcoal;
                background: transparent;
            }
            .icon-tripadvisor {
                text-indent: -.13em;
            }

            @include media-breakpoint-down(sm) {
                width: 38px;
                height: 38px;
                line-height: 38px;
            }
        }
    }
    body.page-template-tpl-owners-group & {
        display: none;
    }
}
