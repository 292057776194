.woocommerce-checkout {
    .woocommerce-info {
        margin-top: 0;
    }
    .woocommerce-form-login-toggle,
    .woocommerce-form-coupon-toggle {
        margin-bottom: 40px;
        background-color: $off-white;
        padding: 12px 16px;
    }
    .woocommerce-form-login {
        margin-bottom: 40px;
        padding: 0 16px;
        p {
            margin-bottom: 30px;
            &:first-child {
                font-weight: 500;
            }
        }
        .form-row {
            display: inline-block;
            margin-right: 10px;
            @include media-breakpoint-down(lg) {
                display: block;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
        label:not(.woocommerce-form-login__rememberme) {
            display: block;
        }
        input.input-text {
            border: 1px solid $border-color;
            background-color: #f8f8f8;
            padding: 13px;
            outline: 0;
            width: 300px;
            @include media-breakpoint-down(lg) {
                width: 100%;
            }          
        }
        label.woocommerce-form-login__rememberme {
            margin-right: 10px;
        }
        .lost_password {
            margin-top: -10px;
        }
    }
    .woocommerce-form-coupon {
        margin-bottom: 40px;
        padding: 0 16px;
        .form-row {
            display: inline-block;
            margin-right: 10px;
            @include media-breakpoint-down(lg) {
                display: block;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
        input.input-text {
            border: 1px solid $border-color;
            background-color: #f8f8f8;
            padding: 13px;
            outline: 0;
            width: 286px;
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }
    }
}

.woocommerce-checkout {
    .form-row {
        margin-left: 0;
        margin-right: 0;
    }
}

#customer_details {
    margin: 0 -30px 40px;
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-down(lg) {
        margin-left: 0;
        margin-right: 0;
    }
    > div {
        width: 50%;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        flex: auto;
        @include media-breakpoint-down(lg) {
            width: 100%;
            display: block;
            flex: none;
            padding: 0;
        }
    }
    input,
    textarea {
        border: 1px solid $border-color;
        background-color: #f8f8f8;
        padding: 10px 20px;
        width: 286px;
        color: $headings-color;
        @include media-breakpoint-down(lg) {
            width: 100%;
        }
        &:focus {
            outline: none;
            outline-offset: unset;
        }
    }
    .form-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
        @include media-breakpoint-down(lg) {
            flex-direction: column;
            align-items: flex-start;
            display: block;
        }
        label {
            padding: 0 10px 0 0;
            @include media-breakpoint-down(lg) {
                display: block;
                margin: 0 0 5px;
            }
        }
        &.create-account {
            label {
                display: inline-block
            }
        }
        &.woocommerce-validated {
            input.input-text,
            .select2-selection {
                box-shadow: inset 2px 0 0 #0f834d;
            }
        }
        &.woocommerce-invalid {
            input.input-text,
            .select2-selection {
                box-shadow: inset 2px 0 0 #e2401c;
            }
        }
        &.form-row-last {
            margin-right: 0 !important
        }
        &.form-row-wide {
            clear: both;
            width: 100%
        }
    }

    #order_comments_field {
        flex-direction: column;
        align-items: unset;
        label {
            display: block;
        }
        span {
            margin: 20px 0 0
        }
        textarea {
            width: 100%;
            min-height: 200px;
        }
    }

    #ship-to-different-address-checkbox {
        width: 20px;
        height: 20px;
        padding: 0;
        &:checked {
            background-color: $turquoise !important;
        }
    }

    .required {
        text-decoration: none;
        color: $turquoise;
        border-bottom: 0 !important;
    }

    .select2-container {
        width: 286px !important;
        @include media-breakpoint-down(lg) {
            width: 100% !important;
        }        
        .select2-selection {
            &:focus {
                //border-color: $turquoise;
                outline: none;
                outline-offset: unset;
            }
        }
        .select2-selection--single {
            border: 1px solid $border-color;
            background-color: #f8f8f8;
            height: auto;
            border-radius: 0;
            .select2-selection__rendered {
                padding: 12px 20px;
                line-height: 1.5;
                height: calc(1.5em + 0.75rem + 2px);
                height: auto;
            }
            .select2-selection__arrow {
                top: 14px;
            }
        }
    }
}

#order_review {
    > table {
        margin-bottom: 60px;
        tfoot {
            .amount {
                font-weight: 600;
            }
        }
        @include media-breakpoint-down(lg) {
            td {
                display: table-cell;
            }
            tr {
                display: table-row;
            }

            td[data-title]:before {
                display: none;
            }
        }
    }
    .product-name {
        font-weight: 600;
        .cart-item-name {
            display: inline-block;
            margin-bottom: 8px;
        }
    }
}

#payment {
    border: 1px solid $border-color;
    background: $off-white;
    border-radius: 5px;
    a {
        color: darken($primary, 10%);
    }
    .payment_methods {
        margin: 0;
        padding: 0;
        list-style: none outside;

        li {
            position: relative;
            border-bottom: 1px solid $border-color;
            img {
                float: right;
                border: 0;
                padding: 0;
                max-height: 40px;
                margin-top: -8px;
                margin-left: 8px;
                @include media-breakpoint-down(lg) {
                    max-height: 30px;
                    margin-top: -4px;
                }
            }
            &:last-child:not(.woocommerce-notice) {
                padding-bottom: 0;
            }
            p {
                margin: 0;
            }
            > label {
                display: block;
                padding: 1.41575em;
                cursor: pointer;
                transition: .5s;
                margin-bottom: 0;
                &:before {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    line-height: 1;
                    vertical-align: -.125em;
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 900;
                    line-height: inherit;
                    vertical-align: baseline;
                    content: '\f111';
                    color: $charcoal;
                    margin-right: 0.5407911001em;
                    transition: color, ease, 0.2s;
                }
            }
            input[type=radio] {
                display: none;
                &:first-child {
                    border: 0;
                    clip: rect(1px, 1px, 1px, 1px);
                    -webkit-clip-path: inset(50%);
                    clip-path: inset(50%);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                    word-wrap: normal !important;
                    &:checked {
                        + label::before {
                            content: '\f192';
                        }
                    }
                }                
            }
        }
    }
    .payment_box {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        padding: 1em;
        font-size: .92em;
        border-radius: 2px;
        line-height: 1.5;
        background-color: darken($off-white, 5);
        color: #515151;
        &:before {
            content: '';
            display: block;
            border: 1em solid darken($off-white, 5);
            border-right-color: transparent;
            border-left-color: transparent;
            border-top-color: transparent;
            position: absolute;
            top: -.75em;
            left: 0;
            margin: -1em 0 0 2em;                
        }
        fieldset {
            border: 0;
            margin: 0;
            padding: 1em 0 0;
            font-size: .875em;
            label {
                width: auto;
                margin-left: 0;
                margin-bottom: 0;
            }
            .form-row {
                margin-bottom: 1em;
                input[type=checkbox] {
                    margin-right: 5px;
                }
            }
            #cvv {
                width: 22% !important
            }
            .help {
                width: 75%;
                float: right;
            }
        }
        .woocommerce-SavedPaymentMethods {
            padding-top: 1em;
            margin: 0;
            li {
                margin-top: .6180469716em;
                input[type=radio] {
                    margin-right: .236em;
                }
            }
            label {
                margin-bottom: 0;
            }
        }
        ul.wc-saved-payment-methods {
            margin-left: 0;
        }
        .wc-credit-card-form-card-cvc,
        .wc-credit-card-form-card-expiry,
        .wc-credit-card-form-card-number {
            font-size: 1.41575em;
        }
        .wc-credit-card-form-card-number {
            background-repeat: no-repeat;
            background-position: right .6180469716em center;
            background-size: 31px 20px;
            background-image: none;
            &.visa {
                background-image: url('../../assets/img/content/credit-cards/visa.png');
            }
            &.mastercard {
                background-image: url('../../assets/img/content/credit-cards/mastercard.png');
            }
            &.dinersclub {
                background-image: url('../../assets/img/content/credit-cards/diners.png');
            }
            &.maestro {
                background-image: url('../../assets/img/content/credit-cards/maestro.png');
            }
            &.jcb {
                background-image: url('../../assets/img/content/credit-cards/jcb.png');
            }
            &.amex {
                background-image: url('../../assets/img/content/credit-cards/amex.png');
            }
            &.discover {
                background-image: url('../../assets/img/content/credit-cards/discover.png');
            }
        }
    }
    .woocommerce-terms-and-conditions {
        padding: 1em;
        box-shadow: inset 0 1px 3px rgba(0,0,0,.2);
        margin-bottom: 16px;
        background-color: rgba(0,0,0,.05);
        h3 {
            margin-top: 0;
        }
    }
    .place-order,
    > .form-row:last-child {
        margin-bottom: 0;
        float: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 1.41575em !important;
        p {
            font-size: 14px;
        }
        button {
            @extend .btn;
            @extend .btn-lg;
            @extend .btn-yellow;
            margin: auto;
            display: block;
            width: 100%;
        }
    }
}

.woocommerce-thankyou-order-details {
    margin: 0 0 40px;
    padding: 15px 15px;
    list-style: none;
    background-color: $off-white;
    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
    }
    li {
        text-transform: uppercase;
        font-size: .715em;
        line-height: 1;
        padding: 0;
        list-style-type: none;
        margin-bottom: 10px;
        @include media-breakpoint-up(lg) {
            padding: 0 2rem;
            margin-bottom: 0;
            border-right: 1px dashed #d3ced2;
        }
        strong {
            display: block;
            font-size: 1.4em;
            text-transform: none;
            line-height: 1.5;
        }
        &:last-of-type {
            border: none;
            margin-bottom: 0;
        }
    }
}

.woocommerce_deposits_parent_order_summary {
    width: 100%;
    margin: 0 0 40px;
    background-color: $off-white;
    th {
        color: #6e6e6e;
        font-family: GreycliffCF-Bold;
        padding: 10px 15px 5px;
    }
    td {
        padding: 0 15px;
    }
    tr:last-child {
        td {
            padding-bottom: 15px;
        }
    }
}

.woocommerce-order-details {
    > table {
        margin-bottom: 60px;
        tfoot {
            .amount {
                font-weight: 600;
            }
        }
        @include media-breakpoint-down(lg) {
            td {
                display: table-cell;
            }
            tr {
                display: table-row;
            }

            td[data-title]:before {
                display: none;
            }
        }
    }
    .product-name {
        font-weight: 600;
        p {
            font-weight: normal;
        }
    }
}

.woocommerce-customer-details {
    address {
        padding: 25px;
        background-color: $off-white;
        margin-bottom: 0;
        .woocommerce-customer-details--phone {
            margin-bottom: 0;
        }
        .woocommerce-customer-details--email {
            margin-bottom: 0;
        }
    }
    .addresses {
        @include media-breakpoint-up(lg) {
            display: flex;
        }
        > div {
            width: 100%;
            margin-bottom: 30px;
            @include media-breakpoint-up(lg) {
                width: 50%;
                flex: auto;
                margin-bottom: 0;
            }
        }
    }
}

.wc-stripe-elements-field, 
.wc-stripe-iban-element-field {
    padding: 10px !important;
}
