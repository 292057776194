.single-product {
    &.is-not-bike {
        .add-to-cart {
            padding-top: 140px !important;
        }
    }
    .overview {
        display: none;
        text-align: center;
        position: relative;
        @include media-breakpoint-up(lg) {
            display: block;
        }
        .overview-description {
            max-width: 600px;
            margin: auto;
            p {
                @extend .lead;
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
        .product-review-rating {
            margin-bottom: 10px;
        }
        .overview-image {
            text-align: center;
        }        
    }
    .features-list {
        padding-left: 0;
        .feature-item {
            position: relative;
            @include media-breakpoint-up(md) {
                display: flex;
            }
            &:before {
                content: '';
                width: calc(100%);
                position: absolute;
                left: 0;
                bottom: 0;
                background: $border-color;
                height: 1px;
                z-index: 1;
            }
            .img-col {
                @include media-breakpoint-up(md) {
                    width: 58%;
                }
                .rect-outer {
                    padding: 76.7% 0 0;                        
                }
                .rect-inner {
                    background-position: 0 0;
                }
            }
            .desc-col {
                margin: auto;
                padding: 22px;
                width: 100%;
                max-width: 350px;
                text-align: center;
                @include media-breakpoint-up(md) {
                    width: 42%;
                    max-width: none;
                    padding: 0 30px;
                    text-align: left;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;                        
                }
                .desc-col-inner {
                    max-width: 450px;
                    .section-title {
                    }
                    .section-description {
                        padding: 10px 0 0;
                        font-size: 18px;
                    }
                }
            }
            &:nth-child(2n) {
                flex-direction: row-reverse;
                .img-col {
                    margin: 0 0 0 auto;
                }
                .desc-col {
                    margin: auto;
                    padding: 22px;
                    width: 100%;
                    max-width: 350px;
                    text-align: center;
                    @include media-breakpoint-up(md) {
                        width: 42%;
                        max-width: none;
                        padding: 0 30px;
                        text-align: left;
                        align-items: flex-end;
                    }
                }
            }                
        }
    }
    .feature-popups {
        .interact {
            max-width: none;
            position: relative;
            img {
                width: 100%;
            }
        }
    }
    .section-feature-type-1 {
        text-align: center;
        position: relative;
        @include media-breakpoint-up(md) {
            .mobile-layout {
                display: none!important
            }
        }
        .section-description {
            padding: 8px 0 0;
            max-width: 650px;
            margin: auto;
            font-size: 18px;
        }
        .feature-img-wrap {
            padding: 50px 20px 0;
            margin: auto;
            max-width: 790px;
            &:not(.seen) {
                .battery-img {
                    transform: trancharcoalX(-50vh);
                    opacity: 0;
                }
                .black-motor-img {
                    transform: trancharcoalX(50vh);
                    opacity: 0;
                    position: absolute;
                    left: 48%;
                    top: 2%;
                    max-width: 100%;
                    transition: .5s;
                }
            }
            .rect-outer {
                padding: 60% 0 0
            }
            .battery-img {
                left: 1%;
                top: 3%;
                width: 100%
            }
            .battery-img,
            .black-motor-img {
                position: absolute;
                transition: transform .7s,opacity .3s;
            }
            .black-motor-img {
                left: 48%;
                top: 2%;
                max-width: 100%
            }
            &.desktop-layout {
                display: none;
                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
        }
        .section-extra-description {
            padding: 20px 0 0;
            font-size: 18px;
            @include media-breakpoint-up(md) {
                max-width: 690px;
                margin: auto;
                display: flex
            }
            .battery-img {
                @include media-breakpoint-up(md) {
                    max-width: 173%;
                }
            }
            img {
                max-width: 80%;
                display: block;
                margin: 20px auto
            }
            & > div {
                margin: 0 auto;
                text-align: center;
            }
            @include media-breakpoint-up(md) {
                & > div {
                    width: 50%;
                    max-width: 270px;
                    opacity:1;
                    transform: none;
                    &:first-child {
                        opacity: 0;
                        transform: trancharcoalX(-100px);
                        transition: 1s;
                    }
                    &:last-child {
                        opacity: 0;
                        transform: trancharcoalX(100px);
                        transition: 1s;
                    }
                }
                &.seen > div {
                    &:first-child,
                    &:last-child {
                        opacity: 1;
                        transform: none;
                    }
                }
            }
        }
    }
    .product-base {
        margin-top: -40px;
        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }
        .product {
            display: flex;
            flex-direction: column;
            @include media-breakpoint-up(lg) {
                flex-direction: row;
            }
        }
        .product-title {
            line-height: 1;
            margin-bottom: 1rem;
            &.mobile-layout {
                text-align: center;
                padding: 10px 0 0;
            }
            &.desktop-layout {
                display: none;
            }
            @include media-breakpoint-up(md) {
                &.mobile-layout {
                    display: none;
                }
                &.desktop-layout {
                    display: block;
                }
            }
        }
        .product-summary-text {
            margin-bottom: 30px;
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
            b,
            strong {
                font-weight: 700;
            }
            em,
            i {
                font-style: italic;
            }
            h3 {
                font-size: 16px;
                padding: 15px 0 5px;
                font-weight: 600;
            }
            ul {
                padding-left: 0;
                margin-bottom: 0;
                padding-bottom: 15px;
                list-style: circle;
                padding-left: 20px;
                &:last-child {
                    padding-bottom: 0;
                }
                li+li {
                    padding-top: 10px;
                }
            }
        }
        .product-attributes {
            @include font-size(14);
        }
        .woocommerce-product-gallery {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include media-breakpoint-only(lg) {
                padding-right: 2rem;
                width: calc(100% - 2rem);
            }
            @include media-breakpoint-up(xl) {
                width: 50%;
            }
            img {
                width: 100%;
                height: auto;
                margin: 0;
            }
            .zoomImg {
                background-color: $white;
            }
            &__wrapper {

            }
            &__image {
                position: relative;
                a {
                    display: block;
                    img {
                        transition: .5s
                    }
                    &:after {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-30%);
                        transition: .3s;
                        font-weight: 900;
                        font-family: 'Font Awesome 5 Free';
                        content: '\f002';
                        color: $white;
                        font-size: 26px;
                        border-radius: 50%;
                        background: $turquoise;
                        width: 70px;
                        height: 70px;
                        line-height: 70px;
                        text-align: center;
                        display: block;
                        opacity: 0
                    }
                    &:hover {
                        img {
                            opacity: .4;
                        }
                        &:after {
                            opacity: .8;
                            transform: translate(-50%,-50%);
                        }
                    }
                }
            }
            &__trigger {
                position: absolute;
                top: .875em;
                right: .875em;
                display: block;
                height: 2em;
                width: 2em;
                border-radius: 3px;
                z-index: 99;
                text-align: center;
            }
            .flex-viewport {
                margin-bottom: 10px;
            }
            .flex-control-thumbs {
                width: 100%;
                margin: 0;
                padding: 0;
                position: static;
                overflow: hidden;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                max-height: 144px;
                li {
                    list-style: none;
                    cursor: pointer;
                    float: left;
                    margin: 0;
                    padding: 0 15px;
                    display: inline-block;
                    img {
                        height: auto;
                        display: block;
                        cursor: pointer;
                        -moz-user-select: none;
                        opacity: .75;
                        transition: all, ease, .2s;
                        &.flex-active {
                            opacity: 1;
                        }
                    }
                    &:hover {
                        img {
                            opacity: 1;
                        }
                    }                
                }              
            }
            &--columns-2 {
                .flex-control-thumbs {
                    li {
                        width: calc(50% - 0px);
                    }
                }
            }
            &--columns-3 {
                .flex-control-thumbs {
                    li {
                        width: calc(33.333333333% - 0px);
                    }
                }
            }
            &--columns-4 {
                .flex-control-thumbs {
                    li {
                        width: calc(25% - 0px);
                    }
                }
            }
            &--columns-5 {
                .flex-control-thumbs {
                    li {
                        width: calc(20% - 0px);
                    }
                }
            }            
        }
        .entry-summary {
            width: 100%;
            margin-bottom: 40px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
            @include media-breakpoint-up(xl) {
                width: 50%;
                padding: 0 0 0 50px;
            }
            .entry-summary-inner {
                .entry-summary-extra-inner {
                    .price {
                        span {
                            font-size: 28px;
                            font-weight: 700;
                            color: $reddish;
                        }
                    }
                }
            }
        }
        .woocommerce-review-link {
            &:hover {
                text-decoration: none;
            }
        }
        .quantity-wrap {
            padding: 0;
            display: flex;
            align-items: flex-start;
            margin: 0 0 20px 0;
            @include media-breakpoint-up(md) {
                margin: 0 20px 0 0;
            }
            @include media-breakpoint-only(lg) {
                margin: 0;
            }
            .quantity-button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 62px;
                background: $white;
                transition: .2s;
                width: 62px;
                cursor: pointer;
                border: 1px solid $border-color;
                &.icon-minus {
                }
                &.icon-plus {
                }
                &:hover {
                }
            }
            .quantity {
                flex-wrap: wrap;
                display: flex;
                label {
                    visibility: hidden;
                    font-size: 0;
                    position: relative;
                    &:before {
                        font-size: 14.6px;
                        visibility: visible;
                        content: 'Quantity:';
                        text-transform: uppercase;
                        width: 100%;
                        position: absolute;
                        top: -28px;
                        left: -45px;
                    }
                }
                .qty {
                    border-top: 1px solid #e6e6e6;
                    border-bottom: 1px solid #e6e6e6;
                    border-left: none;
                    border-right: none;
                    padding: 7px 4px;
                    max-width: 62px;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    color: $body-color;
                    height: 62px;
                    border-radius: 0;
                }
                input[type=number] {
                    height: 62px;
                    max-width: 62px;
                    padding: 5px 5px 5px 5px;
                    &::-webkit-inner-spin-button, 
                    &::-webkit-outer-spin-button { 
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        margin: 0; 
                    }                    
                }
            }
        }
        .variations {
            select {
                @extend .custom-select;
            }
            .reset_variations {
                display: none !important;
            }
            .variation-selector {
                &.hidden {
                    display: none;
                }
            }
            width: 100%;
            margin-bottom: 20px;
            tbody {
                display: flex;
                flex-wrap: wrap;
                tr {
                    display: block;
                    margin: 20px 0 0;
                    width: 100%;
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        width: 100%;
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                    .label {
                        display: block;
                        @include font-size(14);
                        font-family: 'GreycliffCF-ExtraBold';
                        label {
                            margin-bottom: 0;
                            &:after {
                                content: ':';
                            }
                        }
                    }
                    .value {
                        display: block;
                        margin: 5px 0 0
                    }
                }              
            }
            .tawcvs-swatches {
                margin-bottom: 0 !important;
                &.disabled {
                    position: relative;
                    opacity: .3;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0
                    }
                }
                &[data-attribute_name=attribute_pa_frame-colour],
                &[data-attribute_name=attribute_pa_tyre-wheel-options] {
                    margin: 0 -2px;
                    display: flex;
                    flex-wrap: wrap;
                }
                &[data-attribute_name=attribute_pa_battery-options] {
                    margin: 0 -2px;
                }
                &[data-attribute_name=attribute_pa_frame-colour],
                &[data-attribute_name=attribute_pa_tyre-wheel-options] {
                    padding: 0 0 22px;
                    padding-bottom: 0;
                    position: relative;
                }
                &[data-attribute_name=attribute_pa_frame-colour]:after,
                &[data-attribute_name=attribute_pa_tyre-wheel-options]:after {
                    content: attr(data-current-value);
                    font-size: 12px;
                    transition: .5s;
                    color: #b1b1b1;
                    white-space: nowrap;
                    position: absolute;
                    left: 0;
                    bottom: -20px;
                }
                .swatch {
                    transition: all 0.3s;
                    border-radius: 50%;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    margin-right: 15px;
                    cursor: pointer;
                    position: relative;
                    .disabled {
                        opacity: .3;
                    }
                    .hidden {
                        display: none;
                    }                    
                }
                .swatch-color,
                .swatch-image {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: inline-block;
                    position: relative;
                    font-size: 0;
                    margin: 5px;
                    transition: .5s;
                    cursor: pointer;
                    text-align: center;
                    &:before,
                    &:after {
                        transition: .2s;
                        content: '';
                        position: absolute;
                        border-radius: inherit;
                        opacity: 0;
                        z-index: -1;
                    }
                    &:before {
                        top: -4px;
                        left: -4px;
                        height: calc(100% + 8px);
                        width: calc(100% + 8px);
                        background: $turquoise;
                    }
                    &:after {
                        top: -2px;
                        left: -2px;
                        height: calc(100% + 4px);
                        width: calc(100% + 4px);
                        background: $white;
                    }
                    &.selected {
                        &:after,
                        &:before {
                            opacity: 1;
                        }
                    }
                    &.hidden {
                        display: none;
                    }
                    &.swatch-white {
                        border: 1px solid #b1b1b1;
                    }
                }
                .swatch-color {
                    text-indent: -9999px;
                }
                .swatch-label {
                    width: 100%;
                    display: inline-block;
                    text-align: center;
                    margin-bottom: 5px;
                    border-radius: 2px;
                    display: inline-block;
                    border: 2px solid #e6e6e6;
                    color: $charcoal;
                    font-size: 14px;
                    padding: 0 24px;
                    margin: 2px;
                    transition: .5s;
                    cursor: pointer;
                    width: auto;
                    height: 36px;
                    line-height: 32px;
                    opacity: 1;
                    @include media-breakpoint-up(lg) {
                        width: auto;
                        color: $charcoal;
                        font-size: 14px;
                        padding: 0 15px;
                        margin: 2px;
                        transition: .5s;
                        cursor: pointer;
                    }
                    &:hover {
                        opacity: .7
                    }
                    &.selected {
                        color: $turquoise;
                        border-color: $turquoise;
                    }
                    .swatch__tooltip {
                        display: none;
                    }
                }
            }
        }
        .single_variation_wrap {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .woocommerce-variation-price {
                margin: 0;
                .price {
                    &:before {
                        //content: "PRICE:";
                        display: block;
                        color: $logo-grey;
                        @include font-size(14);
                    }
                }
            }
            .woocommerce-variation-availability {
                margin: 0;
                .stock {
                    margin: 0;
                }
            }
            .woocommerce-variation-add-to-cart {
                display: flex;
                flex-direction: column;
                margin-top: 0px;
                width: 100%;
                @include media-breakpoint-up(md) {
                    flex-direction: row;
                    flex-wrap: wrap;
                }
                .price {
                    margin: 0;
                    display: block;
                    &:before {
                        position: relative;
                        content: "PRICE:";
                        display: block;
                        color: $logo-grey;
                        @include font-size(14);
                    }
                    .suffix {
                        color: $logo-grey;
                    }
                }
            }
        }
        .add-to-cart-row {
            width: 100%;
            padding: 30px 0 0;
            .btn-group {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0;
                .btn,
                .button {
                    flex: none;
                    margin: 0 0 10px;
                    padding: 12px 10px;
                    text-align: center;
                    width: 100%;
                    display: inline-block;
                    border-radius: 4px;
                    @include media-breakpoint-up(md) {
                        width: calc(50% - 5px);
                    }
                    &:last-child {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 0;
                    }                    
                }
            }
        }
        .single_add_to_cart_button {
            @extend .btn;
            @extend .btn-lg;
            @extend .btn-yellow;
            @extend .flex-fill;
        }
        .product-type-simple {
            .price {
                &:before {
                    //content: "PRICE:";
                    display: block;
                    font-size: 14px;
                    color: $logo-grey;
                }
                .suffix {
                    color: $logo-grey;
                }
            }
            .bttn {
                margin: 30px 0 0;
            }

            .cart {
                display: flex;
                margin-top: 30px;
            }
        }
    }
    .assurance {
        text-align: center;
        .quote {
            letter-spacing: 1.2px;
            .author {
                padding: 3px 0 0;
                letter-spacing: -.6px
            }
        }
        .section-description {
            padding: 21px 0 0;
            letter-spacing: .9px;
            @include font-size(24);
            strong {
                font-weight: 700
            }
        }
        .feature-list {
            display: flex;
            justify-content: center;
            flex-wrap: nowrap;
            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
            }
            padding: 20px 0 0;
            list-style: none;
            padding-left: 0;
            li {
                width: 100%;
                max-width: 350px;
                padding: 20px 0 0;
                .item-inner {
                    max-width: 216px;
                    margin: auto;
                    img {
                        max-width: 160px;
                        max-height: 150px;
                        margin: auto
                    }
                    .caption {
                        font-size: 16px;
                        padding: 23px 0 0;
                        line-height: 1.2;
                        em,
                        i {
                            font-style: italic;
                        }                          
                    }
                }
            }          
        }
    }
    .reviews {
        .section-title {
            letter-spacing: 1.4px;
            text-align: center;
            font-style: normal;
            margin-bottom: 0.25rem;
        }
        .section-description {
            padding: 9px 0 0;
            line-height: 1.1;
            text-align: center;
            em,
            i {
                font-style: italic;
            }
        }
        .block-review-list {
            padding: 51px 0 0;
            &__item {
                background: #f1f1f1;
                padding: 50px 20px;
                margin: 0 -10px;
                @include media-breakpoint-up(sm) {
                    display: flex;
                }
                &-title {
                    font-size: 20px;
                    font-weight: 700;
                    padding: 12px 0 0;
                }
                &-description {
                    padding: 14px 30px 0 0;
                    line-height: 1.2;
                    p {
                        font-size: 1.0625rem;
                        font-weight: 400;
                        line-height: 1.5;
                        margin-bottom: 0;
                    }
                }
                &-see-more-btn-wrap {
                    padding: 14px 0 0;
                    a {
                        color: $turquoise;
                        text-decoration: underline;
                        display: inline-block;
                        font-size: 14px;
                        transition: color .5s;
                    }
                }
                &.block-review-list__item {
                    margin: 18px -10px 0;
                }
                &-col-icon {
                    text-align: center;
                    padding: 0 10px;
                }
                @include media-breakpoint-up(sm) {
                    &-col-icon {
                        width: 21%;
                    }
                }
                &-col-content {
                    text-align: center;
                    padding: 0 10px;
                }
                @include media-breakpoint-up(sm) {
                    &-col-content {
                        width: 79%;
                        text-align: left;
                    }
                }
                &-rating {
                    text-align: center;
                    @include media-breakpoint-up(sm) {
                        text-align: left;
                    }
                }
                &-avatar {
                    position: relative;
                    display: inline-block;
                    padding: 0 0 11px;
                    width: 100px;
                    height: 100px;
                    img:not(.verified-buyer-badge) {
                        width: 100%;
                        height: auto;
                    }
                    .verified-buyer-badge {
                        position: absolute;
                        bottom: -5px;
                        width: calc(119% - 14px);
                        left: 50%;
                        transform: trancharcoalX(-50%)
                    }
                }
                &-author {
                    font-weight: 700;
                    padding: 4px 0 0;
                    font-size: 18px
                }
            }
            &-info {
                max-width: 580px;
                margin: auto;
                padding: 37px 0 0;
                display: flex;;
                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                &__average-score {
                    font-size: 60px;
                }
                &__total-reviews {
                    //padding: 10px 0 0 17px;
                    padding: 0 0 0 17px;
                }
                &_total-reviews-bar {
                    i {
                        margin: 0 1.9px;
                    }
                }
                @include media-breakpoint-up(sm) {
                    &__left-col {
                        width: calc(50% - 2px);
                        padding: 0 25px 0 0;
                        border-right: 2px solid #dfdfdf;
                        &.full {
                            border-right: none;
                            padding: 0;
                            text-align: center;
                            margin: 0 auto;
                        }
                    }
                    &__right-col {
                        width: calc(50% - 0px);
                        padding: 0 0 0 23px;
                    }
                }
                &__total-info {
                    display: flex;
                    align-items: center;

                }
                &__total-reviews-by-rating {
                    padding: 10px 0 0;
                    margin-bottom: 0;
                    list-style: none;
                    li {
                        transition: opacity .7s;
                        cursor: pointer;
                        &:hover {
                            opacity: .7;
                        }
                    }
                }
                &__total-reviews-label {
                    font-size: 18px;
                    padding: 4px 0 0
                }
                &_write-btn-wrap {
                    padding: 10px 0 0;                       
                    .button {
                        min-width: 0;
                        width: 100%;
                        padding: 12px;
                        display: block;
                    }
                }
            }
            &__pagination {
                padding: 38px 0 0;
                text-align: center;
                li {
                    display: inline-block;
                    margin: 0 11px;
                    a {
                        color: #b3b3b3;
                        cursor: pointer;
                        transition: color .5s, opacity .5s, background .5s;
                        &:hover {
                            color: $turquoise;
                            text-decoration: none;
                        }
                    }
                    &.active {
                        a {
                            color: $turquoise;
                        }
                    }
                }
                .next,
                .previous {
                    font-size: 12px;
                    position: relative;
                    bottom: 1px;
                }
            }
            &__no-results {
                padding: 50px 0;
                text-align: center;
            }
        }
    }
    .sells {
        .section-title {
            font-size: 30px;
            letter-spacing: -1.3px;
            font-style: normal;
            text-transform: uppercase;
            text-align: center;
            @include media-breakpoint-up(xl) {
                text-align: left;
            }
        }
        .slick-slider {
            .slick-dots {
                width: 100%;
                margin: 10px auto 10px;
                text-align: center;
                padding-left: 0;
                li {
                    display: inline-block;
                    button {
                        font-size: 0;
                        color: transparent;
                        width: 12px;
                        height: 12px;
                        padding: 0;
                        border: 1px solid $turquoise;
                        background: $white;
                        transition: .3s;
                        border-radius: 50%;
                        margin: 10px;
                    }
                    &.slick-active,
                    &:hover {
                         button {
                            background: $turquoise;
                            transition: .3s;
                        }
                    }
                }
            }
        }
        .slick-slide {
            padding: 0 10px;
            text-align: center;
            &:focus {
                outline: none;
                box-shadow: none;
            }    
            > div {
                @include media-breakpoint-up(lg) {
                    border: 2px solid #e3e3e3;
                }
                min-height: 306px;
                padding: 18px;
                a {
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    text-decoration: none;
                }
                *,
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        .product-img {
            padding: 5px 0 10px;
            img {
                max-width: 100%;
                height: auto;
                margin: auto;
                display: block;
                max-height: 185px;
            }
        }
        .product-text {
            padding: 0 10px;
        }
        .product-title {
            text-transform: uppercase;
            font-size: 1.0625rem;
            font-family: $headings-font-family;
            margin-bottom: 0;
        }
        .product-description {
            font-size: 14px;
            padding: 5px 0 0;
            letter-spacing: -.5px;
            color: $charcoal;
            p {
                text-overflow: ellipsis;
                overflow: hidden;
                height: 3em;
                white-space: nowrap;
            }
        }
        .slick-arrow {
            position: absolute;
            color: $turquoise;
            transition: .5s;
            z-index: 10;
            &:hover {
                opacity: .75;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }    
        }
        .slick-disabled {
            opacity: 0;
            visibility: hidden;
        }
        .slick-next {
            right: -30px;
            top: calc(50% - 17px);
            font-size: 22px;
        }
        .slick-prev {
            left: -30px;
            top: calc(50% - 17px);
            font-size: 22px;
        }
    }
}

#write-review-modal {
    .write-review {
        max-width: 768px;
        margin: 0 auto;
    }
    .close {
        right: 20px;
        top: 10px;
    }    
    .modal-dialog {
        border: 5px solid #15babd;
        background: $white;
    }
    .modal-content {
        border: none;
        border-radius: 0;
    }
    .modal-title {
        text-align: center;
        padding: 0 45px;
        margin-bottom: 10px;
    }
    .section-form {
        padding: 20px 40px;
        .g-recaptcha {
            > div {
                margin: 0 auto;
            }
        }
        .form-label {
            font-weight: 600;
        }
        .form-control {
            border-color: #ccc;
            &:focus {
                border-color: $turquoise;
                box-shadow: none;
            }
        }
    }
    .write-review-icon-carousel {
        max-width: 110px;
        margin: 0 auto;
        padding: 0 10px;
        .slick-arrow {
            position: absolute;
            top: 50%;
            color: rgb(238, 104, 69);
            transform: trancharcoalY(-50%);
            font-size: 22px;
            z-index: 111;
            cursor: pointer;
        }
        .icon-arrow-prev {
            right: 100%;
        }
        .icon-arrow-next {
            left: 100%;
        }
    }
    .rating-box {
        text-align: center;
        margin-bottom: 20px;
        .rating {
            .star {
                position: relative;
                vertical-align: middle;
                width: 32px;
                height: 42px;
                display: inline-block;
                color: $turquoise;
                cursor: pointer;
                &:before,
                &:after {
                    font-family: 'Font Awesome 5 Free';
                    position: absolute;
                    left: 0;
                    top: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 28px;
                }
                &:before {
                    font-weight: 200;
                    content: '\f005';
                }
                &:after {
                    font-weight: 900;
                    content: '\f005';
                    opacity: 0;
                }
                &.selected {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.product-review-rating,
.block-review-list__item-rating,
.block-review-list-info__total-reviews,
.block-review-list-info__total-reviews-by-rating {
    .review-star-rating-bar {
        cursor: pointer;
        transition: opacity .5s;
        font-size: 20px;
        display: inline-block;
        &:hover {
            opacity: .7;
        }
        i {
            margin: 0;
            color: $yellow;
        }
        &-label {
            margin: 0 0 0 9px;
        }        
    }
}

.deposits-frontend-wrapper {
    margin: 20px 0;
    padding: 10px 20px 5px;
    background-color: $white;
    .deposit-notice {
        color: $dark;
        font-weight: bold;
        margin-bottom: .5rem;
    }
}
