.hero {
    position: relative;
    min-height: calc(100vh - 88px);
    body:not(.home) & {
        min-height: calc(80vh + 0px);
    }
    body.is-blog &,
    body.single-product &,
    body.page-template-tpl-owners-manual & {
        min-height: calc(60vh - 0px);
    }
    body.page-template-tpl-be-an-investor &,
    body.page-template-tpl-own-a-business & {
        min-height: calc(100vh - 124px);
    }
    body.post-type-archive-press &,
    body.page-template-tpl-incentives &,
    body.page-template-tpl-info & {
        min-height: calc(67.5vh + 0px);
    }
    body.single-location &,
    body.page-template-tpl-tours &,
    body.page-template-tpl-rentals & {
        min-height: calc(30vh + 0px);
        @include media-breakpoint-up(md) {
            min-height: calc(57.5vh + 0px);
        }
    }
    @include media-breakpoint-up(lg) {
        min-height: calc(100vh - 124px);
    }
    .hero-outer {
        top: 0;
        bottom: 0;
        left: -12%;
        right: -12%;
        overflow: hidden;
        position: absolute;
        background-size: cover;
        background-color: $white;
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 0 0 150% 280%/0 0 1200px 2500px;
        @include media-breakpoint-down(md) {
            border-radius: 0 0 150% 150%/0 0 150px 150px;
        }
        body:not(.home) & {
            background-position: 50%;
            //border-radius: 0 0 250% 250%/0 0 1750px 1750px;
            &:after {
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                z-index: 20;
                position: absolute;
                background-color: rgba($charcoal, .25);
            }
        }
        body.home & {
            background-position: 50% 40%;
            @include media-breakpoint-only(md) {
                background-size: 124%;
            }
            @include media-breakpoint-up(md) {
                background-position: 20% 70%;
            }
            @include media-breakpoint-up(lg) {
                background-position: 100% 69%;
            }
            @include media-breakpoint-up(xl) {
                background-position: 100% 40%;
            }
            &:after {
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                z-index: 20;
                position: absolute;
            }
        }
        body.post-type-archive-testimonial & {
            background-position: 50% 75%;
        }
        body.post-type-archive-news & {
            background-position: 50% 55%;
        }
        body.post-type-archive-press & {
            background-position: 50% 45%;
        }
        body.page-template-tpl-be-an-investor &,
        body.page-template-tpl-own-a-business & {
            background-size: initial;
            background-position: 0% 20%;
            &:after {
                background-color: rgba($black, .5) !important;
            }
            @media only screen and (min-width: 1921px) {
                background-size: cover;
            }
        }
        body.page-template-tpl-info &,
        body.page-template-tpl-press-centre & {
            background-position: 50% 91%;
        }
        body.page-template-tpl-incentives & {
            //border-radius: 0 0 250% 250%/0 0 1800px 1800px;
        }
        body.single-location &,
        body.page-template-tpl-tours &,
        body.page-template-tpl-rentals & {
            background-position: 50% 59%;
        }
        &.no-curve {
            left: 0;
            right: 0;
            border-radius: 0 !important;
        }        
        &.is-dull {
            &:after {
                background-color: rgba($charcoal, .25) !important;
            }
            &.is-duller {
                &:after {
                    background-color: rgba($charcoal, .65) !important;
                }
            }
        }        
    }
    .hero-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 21;
        height: 100%;
        margin: 0 auto;
        max-width: 1200px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% calc(100% + 20px);
        body.page-template-tpl-own-a-business & {
            max-width: none;
        }
        &.has-pulled-content {
            height: calc(100% - 200px);
        }
        .hero-content {
            .hero-inside {
                margin: 0 auto;
                max-width: 494px;
                @include media-breakpoint-down(sm) {
                    padding: 0 calc(12% + 10px);
                }
                body.page-template-tpl-be-an-investor &,
                body.page-template-tpl-own-a-business & {
                    @include media-breakpoint-down(sm) {
                        padding: 0 20px;
                    }
                    @include media-breakpoint-up(xl) {
                        max-width: 50%;
                    }
                }
                body.is-blog &,
                body.post-type-archive-testimonial & {
                    max-width: none;
                }
            }
            .hero-title {
                opacity: 1;
                color: $white;
                font-size: 56px;
                letter-spacing: -0.025em;
                font-family: 'GreycliffCF-DemiBold';
                @include media-breakpoint-down(sm) {
                    line-height: 48px;
                }                
                body.page-template-tpl-be-an-investor &,
                body.page-template-tpl-own-a-business & {
                    //margin-top: -50px;
                    @include font-size(50);
                    letter-spacing: -0.1rem;
                    @include media-breakpoint-down(sm) {
                        line-height: 1;
                        br {
                            display: none;
                        }
                    }
                }
            }
            .hero-subtitle {
                color: $white;
                @include font-size(22);
                letter-spacing: normal;
                font-family: 'GreycliffCF-DemiBold';
                @include media-breakpoint-up(sm) {
                    @include font-size(25);
                }
                body.home & {
                    margin: 0 auto;
                    max-width: 494px;
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 2.5rem;
                    }
                    @include media-breakpoint-up(sm) {
                        span {
                            display: block;
                            &:last-child {
                                letter-spacing: .0075em;
                                @include font-size(30);
                            }
                        }
                    }
                }
                body.page-template-tpl-be-an-investor &,
                body.page-template-tpl-own-a-business & {
                    margin: 15px auto 20px;
                    @include font-size(20);
                    font-family: 'GreycliffCF-Light';
                    @include media-breakpoint-down(sm) {
                        br {
                            display: none;
                        }
                    }
                }
                body.post-type-archive-testimonial & {
                    @include font-size(18);
                }                
            }
            .hero-descriptor,
            .hero-buttons {
                opacity: 1;
            }
            .hero-tag,
            .hero-date,
            .hero-descriptor {
                color: $white;
            }
            .hero-buttons {
                display: flex;
                flex-direction: column;
                @include media-breakpoint-down(sm) {
                    padding: 0 10px;
                }
                @include media-breakpoint-up(md) {
                    flex-direction: row;
                    justify-content: space-between;
                }
            }
        }
    }
    .hero-icon {
        color: $white;
        font-size: 6rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -3rem;
        margin-top: -3rem;
        z-index: 21;
    }
    &.hero-full {
        min-height: calc(100vh - 124px) !important;
    }
}

.watch-video {
    height: 62px;
    text-align: center;
    button {
        color: $white;
        line-height: 62px;
        &:hover {
            color: $turquoise;
            text-decoration: none !important;
        }
    }
}


