.woocommerce-cart {
    h2 {
        font-style: normal;
        margin-bottom: 20px;
    }

    table.cart {
        margin-bottom: 40px;
        thead {
            display: none;
            @include media-breakpoint-up(md) {
                display: table-header-group;
                background-color: $nearly-white;
            }
            th {

            }
        }

        tr:last-child {
            td {
                border: none;
                padding: 0;
            }
        }

        tr.cart_item {
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0;
            }
            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
            }
        }

        td.product-remove {
            a {
                font-size: 30px;
                &:hover {
                    text-decoration: none;
                }
            }
            @include media-breakpoint-down(sm) {
                padding: 0;
                border: none;
                display: inline-block;
                a {
                    line-height: 1;
                }
            }
        }

        td.product-name {
            .cart-item-name {
                font-family: 'GreycliffCF-Bold';
            }
        }

        td.product-thumbnail {
            text-align: center;
            a {
                display: inline-block;
            }
            img {
                display: block;
                width: 80px;
                height: auto;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        td.actions {
            position: relative;
            .continue-shopping {
                @extend .btn;
                @extend .btn-yellow;
                line-height: 48px;
                @include media-breakpoint-up(sm) {
                    position: absolute;
                    top: 20px;
                    left: 0px;
                }
                @include media-breakpoint-down(sm) {
                    display: block;
                    width: 100%;
                }
            }
        }

        button[name=update_cart] {
            @extend .btn;
            display: none;
            line-height: 48px;
            margin: 0 0 0 auto;
            &:not(:disabled):not(.disabled) {
                display: block;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }

    .product-quantity {
        .quantity-wrap {
            padding: 0;
            margin: 0;
            display: flex;
            @include media-breakpoint-down(sm) {
                display: inline-flex;
            }
            .quantity-button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                transition: .2s;
                width: 40px;
                cursor: pointer;
                border: 1px solid $border-color;
                background-color: $white;
                .icon-minus {
                }
                .icon-plus {

                }
            }
            .quantity {
                display: flex;
                flex-wrap: wrap;
                label {
                    visibility: hidden;
                    font-size: 0;
                    position: relative;
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
                .qty {
                    border-top: 1px solid $border-color;
                    border-bottom: 1px solid $border-color;
                    border-left: none;
                    border-right: none;
                    padding: 7px 4px;
                    max-width: 40px;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    color: $body-color;
                    font-weight: 700;
                    height: 40px;
                    border-radius: 0;
                }
                input[type=number] {
                    padding: 5px;
                    max-width: 40px;
                    height: 40px;
                    text-align: center;
                    &::-webkit-inner-spin-button, 
                    &::-webkit-outer-spin-button { 
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        margin: 0; 
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
        }        
    }

    .coupon {
        display: flex;
        justify-content: flex-end;
        @include media-breakpoint-down(sm) {
            margin-top: -20px;
            margin-bottom: 20px;
        }
        @include media-breakpoint-up(sm) {
            padding-top: 20px;
        }
        label {
            line-height: 48px;
            margin-right: 10px;
            margin-bottom: 0;
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
        input[type=text] {
            border-radius: 0px;
            padding: 12px 10px;
            border: 1px solid $border-color;
            @include media-breakpoint-down(sm) {
                width: 50%;
                flex: 1 1 auto;
            }
            @include placeholder(lighten($muddy-green, 20%));
        }
        .button {
            @extend .btn;
            @extend .btn-yellow;
        }
    }

    .cart_totals {
        margin-bottom: 4rem;
        > table {
            @include media-breakpoint-down(lg) {
                td {
                    display: table-cell;
                }
                tr {
                    display: table-row;
                }

                td[data-title]:before {
                    display: none;
                }
            }
        }        
        p.woocommerce-shipping-destination {
            margin-bottom: 0;
        }
        .wc-proceed-to-checkout {
            padding: 10px 0 0;
            text-align: center;
            a.checkout-button {
            }
        }
    }

    .cross-sells {
        h2 {
            font-size: 30px;
        }
        ul.products {
            justify-content: flex-start;
            li {
                a {
                    padding: 20px;
                    h2 {
                        letter-spacing: -.5px;
                    }
                    .price {
                        display: block;
                    }
                }
            }
        }
    }
}
