.header {
    top: 0;
    left: 0;
    right: 0;
    z-index: 22;
    position: static;
    .container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    &-top {
        padding: 20px 0;
        @include media-breakpoint-down(lg) {
            box-shadow: 0 8px 18px 0 rgba(233, 233, 233, 0.5);
        }
    }
    &-bottom {
        display: none;
        line-height: 36px;
        @include media-breakpoint-up(lg) {
            display: block;
        }
        body.menu-open & {
            left: 0;
            right: 0;
            top: 78px;
            bottom: 0;
            width: 100%;
            display: block;
            position: fixed;
            @extend .padded;
            z-index: $zindex-fixed;
        }
    }
    @include media-breakpoint-down(md) {
        body.scrolled & {
            position: fixed;
            z-index: $zindex-fixed;
        }
    }
    body.admin-bar & {
        top: 32px;
    }
    .logo { 
        height: 38px;
        display: block;
        overflow: hidden;
        @include media-breakpoint-up(sm) {
            height: 48px;
        }
        @include media-breakpoint-up(lg) {
            height: 50px;
            margin-top: -5px;
        }
    }
    .tagline {
        color: $turquoise;
        margin-left: 35px;
        margin-bottom: -8px;
        align-self: flex-end;
        @include font-size(34);
        font-family: 'better_timesregular', serif;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .top-links {
        display: flex;
        flex-wrap: nowrap;
        margin-left: auto;
        .quick-tel,
        .quick-email {
            display: flex;
            font-size: 12px;
            color: $logo-grey;
            margin-right: 50px;
            align-items: center;
            @include media-breakpoint-up(sm) {
                font-size: 14px;
            }
            @include media-breakpoint-up(md) {
                font-size: 18px;
            }
        }
        .quick-email {
            i {
                margin-bottom: -3px;
            }
        }
        .account-link {
            //margin-right: 40px;
        }
        .my-talio {
            display: flex;
            color: $logo-grey;
            //padding: 0 20px;
            //min-width: 220px;
            //max-width: 220px;
            //margin-right: 40px;
            position: relative;
            align-items: center;
            text-decoration: none;
            background-color: $yellow;
            i {
                margin-right: 15px;
                color: $logo-grey;
                @include font-size(34);
            }
            span {
                font-size: 14px;
                line-height: 1em;
                font-weight: 400;
                b {
                    display: block;
                    font-weight: 700;
                }
            }
            #my-talio-value {
                font-weight: 700;
                @include font-size(17);
                text-transform: uppercase;
                a {
                    color: $logo-grey;
                }
            }
            &.has-talio {
                #my-talio-value {
                    text-transform: capitalize;
                } 
            }
            #my-talio-info {
                visibility: hidden;
                opacity: 0;
                transform: translateY(10px);
                transition: transform .3s 0s, opacity .5s 0s, visibility 0s .5s;
                position: absolute;
                top: 100%;
                width: 100%;
                padding: 10px;
                font-size: 14px;
                left: 0;
                background: $white;
                color: $turquoise;
                @include media-breakpoint-down(lg) {
                    display: none;
                }
                &:before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    top: -10px;
                    border-style: solid;
                    border-width: 0 10px 10px;
                    border-color: transparent transparent $white
                }
                a {
                    display: block;
                    margin-bottom: 10px;
                    text-decoration: none;
                    line-height: 1.15;
                    &:hover {
                        text-decoration: none;
                    }
                }
                a.btn {
                    font-size: 12px;
                    text-decoration: none;
                    margin: 10px 0 0;
                    padding: 8px 5px;
                    letter-spacing: 0;
                    border: 1px solid $turquoise;
                    background: $turquoise;
                    border-radius: 3px;
                    text-align: center;
                    color: $white;
                    &:hover {
                        color: $turquoise;
                        background: $white;
                    }
                }
            }
            &:hover {
                #my-talio-info {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                    transition: transform .3s 0s, opacity .5s 0s, visibility 0s 0s;
                }
            }
        }
        .quick-cart {
            display: flex;
            align-items: center;
            margin-right: 20px;
            @include media-breakpoint-up(lg) {
                margin-right: 40px;
            }
            a {
                position: relative;
                color: $logo-grey;
                text-align: center;
                .icon-wrap {
                    display: inline-block;
                    text-align: center;
                    transition: .5s;
                    font-size: 13px;
                    font-weight: 600;
                    line-height: normal;
                    i.fa-shopping-cart,
                    i.fa-shopping-basket {
                        @include font-size(34);
                    }
                }
                &:hover {
                    .icon-wrap {
                        //color: $turquoise;
                    }
                }
                .cart-count {
                    top: -9px;
                    right: -8px;
                    width: 18px;
                    height: 18px;
                    display: block;
                    font-size: 12px;
                    line-height: 18px;
                    color: $logo-grey;
                    position: absolute;
                    text-align: center;
                    border-radius: 100%;
                    background-color: $turquoise;
                    font-family: 'GreycliffCF-ExtraBold';
                }
            }
        }
        .toggle,
        .account-link {
            display: flex;
            align-items: center;
            a {
                position: relative;
                color: $logo-grey;
                text-align: center;
                @include font-size(32);
            }
        }
        .toggle {
            margin-left: 20px;
            @include media-breakpoint-up(lg) {
                display: none;
            }            
        }
    }
}

.header {
    .socials {
        a {
            color: $off-white;
            font-size: 20px;
            margin-right: 5px;
            &:hover {
                color: $turquoise;
            }
            &:last-child {
                margin-right: 0;
            }
            body:not(.home):not(.page-template-tpl-location):not(.page-template-tpl-landing):not(.page-template-tpl-contact):not(.page-template-tpl-thanks) & {
                color: $charcoal;
                &:hover {
                    color: $turquoise;
                }
            }
        }
    }
}
