.btn-text-sm {
    font-size: $font-size-sm;
}
.btn-text-base {
    font-size: $font-size-base;
}

.has-promo-badge {
    position: relative;
    .promo-badge {
        width: 60px;
        height: 60px;
        line-height: 1.2;
        text-align: center;
        border-radius: 100%;
        background-color: $white;
        position: absolute;
        right: -30px;
        top: -30px;
        overflow: hidden;
        color: $headings-color;
        .promo-badge-inner {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .from,
        .price {
            display: block;
            @include font-size(14);
            font-family: 'GreycliffCF-Bold';
        }
        .price {
            color: $turquoise;
            @include font-size(17);
        }
    }
    @include hover() {
        .promo-badge {
            color: $headings-color;
        }
    }
}
