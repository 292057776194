// Sections
@import "shop.scss";
@import "product.scss";
@import "cart.scss";
@import "checkout.scss";
@import "account.scss";

// Columns
div.woocommerce {
	@include clearfix;
	.col-1,
	.col-2 {
		max-width: unset;
	}
}

// Password Strength
.woocommerce-password-strength {
    font-weight: 600;
    padding: .5407911001em 0;
    text-align: right;
    font-size: .875em;
	&.strong {
	    color: #0f834d
	}
	&.short {
	    color: #e2401c
	}
	&.bad {
	    color: #e2401c
	}
	&.good {
	    color: #3d9cd2
	}
}

// Notices

.woocommerce-notices-wrapper {
    p.no-comments,
    .woocommerce-info,
    .woocommerce-error,
    .woocommerce-message,
    .woocommerce-noreviews, {
        margin: 0;
        list-style: none;
        margin-bottom: 30px;
        background-color: $off-white;
        padding: 12px 16px;

        a {
        	&:hover {
        		text-decoration: none;
        	}
        }
    }
    p.no-comments,
    .woocommerce-info,
    .woocommerce-message,
    .woocommerce-noreviews, {
        //border-left: 6px solid #28a745;
    }
    .woocommerce-error {
        //border-left: 6px solid red;
    }
}

// Forms
.woocommerce-ResetPassword,
.woocommerce-form {
	.form-row:not(.place-order) {
		display: block;
	    margin-right: 0;
	    margin-left: 0;
	    margin-bottom: 1.5rem;
	    label {
		//label:not(.woocommerce-form-login__rememberme) {
		    display: block;
		}
		label.woocommerce-form-login__rememberme {
		    //margin-right: 10px;
		    margin-bottom: 10px;
		}
        input,
        textarea {
            border: 1px solid $border-color;
            background-color: $off-white;
            padding: 10px 20px;
            color: $body-color;
            &:focus {
                outline: none;
                outline-offset: unset;
            }
        }
		input,
		select,
		textarea {
		    width: 100%;
		}

		input[type=checkbox],
		input[type=radio] {
		    width: auto;
		}

		&.create-account {
			label {
		    	display: inline-block
			}
		}

		&.woocommerce-validated {
			input.input-text {
		    	box-shadow: inset 2px 0 0 #0f834d;
		    }
		}

		&.woocommerce-invalid {
			input.input-text {
		    	box-shadow: inset 2px 0 0 #e2401c;
		    }
		}

		&.form-row-last {
		    margin-right: 0 !important
		}

		&.form-row-wide {
		    clear: both;
		    width: 100%
		}
		.required {
			text-decoration: none;
			color: $turquoise;
			border-bottom: 0 !important;
		}
	}
    .select2-container {
        .select2-selection {
            &:focus {
                border-color: $turquoise;
                outline: none;
                outline-offset: unset;
            }
        }
        .select2-selection--single {
            border: 1px solid #e4e4e4;
            height: auto;
            border-radius: 0;
            .select2-selection__rendered {
                padding: 12px 20px;
                line-height: 1.5;
                height: calc(1.5em + 0.75rem + 2px);
                height: auto;
            }
            .select2-selection__arrow {
                top: 50%;
            }
        }
    }
	.woocommerce-LostPassword {
		margin-top: -10px;
	}
}

// Tables
.shop_table {
	width: 100%;
    text-align: left;
    margin-bottom: 20px;
    th {
        color: darken($body-color, 10%);
        font-family: 'GreycliffCF-Bold';
    }
    th,
    td {
        padding: 12px 18px;
        vertical-align: middle;
        border: 1px solid $border-color;
        small {
            font-weight: 400;
        }
    }
	@include media-breakpoint-down(sm) {
	    td,
	    tr {
	        display: block;
	    }
	    td[data-title]:before {
            width: 35%;
	        margin: 0 0 5px;
            display: inline-block;
            content: attr(data-title) ":";
            color: darken($body-color, 10%);
            font-family: 'GreycliffCF-Bold';
	    }
	}
	.product-remove {
		text-align: center;
	}
}

.variation,
.wc-item-meta {
    margin: 0 2px;
    padding: 0;
    font-size: 85%;
    list-style: none;
    dt {
        font-weight: 700;
    }
    dd {
        font-weight: 400;
        p {
            margin-bottom: 0;
        }
    }
    dd,
    dt {
        width: 50%;
        display: inline-block;
        margin: 0 -2px;
    }
    li {
    	.wc-item-meta-label,
    	p {
    		margin: 0 -2px;
	        width: 50%;
	        display: inline-block;
    	}
    }
}

ul.woocommerce-shipping-methods {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        label {
            margin-bottom: 0;
        }
    }
}
