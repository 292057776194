.screen-reader-text {
    @extend .sr-only;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $off-white inset;
}

.grecaptcha-badge {
    visibility: collapse !important;  
}

.form-messages {
    font-size: 14px;
    line-height: 24px;
    color: $body-color;
    margin-bottom: 1.5rem;
    background-color: $white;
    border-left: 5px solid $white;
    &.error,
    &.success {
        padding: 8px 12px;
    }
    &.error {
        border-color: $danger;
    }
    &.success {
        border-color: $success;
    }
}

.form-label {
    color: $headings-color;
    span {
        color: $yellow;
    }
}

.col-form-label {
    font-family: 'GreycliffCF-DemiBold';
    color: $headings-color;
    span {
        color: $danger;
    }
}


// Form control
.form-control {

    @if $form-validation-input-shadow == false {
        &:active,
        &.active,
        &:focus,
        &.focus {
            box-shadow: none !important;
        }
    }

    // Solid style
    &.form-control-solid {
        background-color: $input-solid-bg;
        border-color: $input-solid-bg;
        @include placeholder {
            color: $input-solid-placeholder-color;
        }
        color: $input-solid-color;
        transition: $transition-base;

        &:active,
        &.active,
        &:focus,
        &.focus {
            background-color: $input-solid-bg-focus;
            border-color: $input-solid-bg-focus;
            color: $input-solid-color;
            transition: $transition-base;
        }
    }
}


// Custom inputs
.custom-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    @if $form-validation-input-shadow == false {
        &:active,
        &.active,
        &:focus,
        &.focus {
            box-shadow: none !important;
        }
    }
    
    // Solid style
    &.custom-select-solid {
        background-color: $input-solid-bg;
        border-color: $input-solid-bg;
        color: $input-solid-color;
        transition: $transition-base;
        box-shadow: none;

        &:active,
        &.active,
        &:focus,
        &.focus {
            background-color: $input-solid-bg-focus;
            border-color: $input-solid-bg-focus;
            color: $input-solid-color;
            transition: $transition-base;
        }

        &[multiple] option:checked {
            background: #e5eaee linear-gradient(0deg, #e5eaee 0%, #e5eaee 100%);
        }

        &[multiple]:focus option:checked {
            background: $input-solid-color linear-gradient(0deg, $input-solid-color 0%, $input-solid-color 100%);
        }
    }
}

.custom-file {
    //width: 100%;
}

.custom-file-input:focus ~ .custom-file-label {
    box-shadow: none !important;
}

.custom-file-label {
    text-align: left;

    &:after {
        float: left;
    }

    // Solid style
    &.custom-file-label-solid {
        background-color: $input-solid-bg;
        border-color: $input-solid-bg;
    }
}
