.widget {
    
    margin-bottom: 65px;

    .widget-title {
        text-transform: uppercase;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &.widget-search {
        .form-control {
            border-right: 0;
        }
        .input-group-append {
            color: #1d1d1d;
            background-color: transparent;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.045);
            .input-group-text {
                background-color: $white;
            }
        }
    }
    
    .category-list {
        li {
            padding: 5px 0;
            &:first-child {
                padding: 0 0 5px 0;
            }
            a {
                font-size: 14px;
                span {
                    color: $gray-600;
                    &:before {
                        content: '/';
                        margin-right: 5px;
                        padding-left: 8px;
                    }
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }       
    }
    
    .widget-posts {
        
        li {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 10px 0;
            a {
                display: block;
                line-height: 20px;
            }
            img {
                width: 75px;
                margin-right: 20px;
                float: left;
            }
        }
        .widget-posts-details {
            position: relative;
            overflow: hidden;
            font-size: 11px;
            line-height: 14px;
            a {
                font-size: 14px;
                margin-bottom: 5px;
            }
        }
    }
    
    .tags {
        margin-top: 25px;
        a {
            border: #ccc;
            padding: 0 10px;
            margin: 0 5px 9px 0;
            text-transform: uppercase;
            font-size: 10px;
            color: red; 
            display: inline-block;
            line-height: 26px;
            &:hover {
                border: 1px solid rgba(red, 1);
                background-color: red;
                color: $white;
                text-decoration: none;
            }
        }
    }
}