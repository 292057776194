.articles {
    .post {
        margin-bottom: 30px;
        @include media-breakpoint-up(md) {
            margin-bottom: 60px;
        }
        &-inner {
            height: 100%;
            position: relative;
            border: solid 1px #ebebeb;
        }
        &-link {
            color: $body-color;
            @include hover-focus() {
                text-decoration: none;
            }
        }
        &-featured-media {
            margin-bottom: 0;
            overflow: hidden;
        }
        &-body {
            padding: 20px 20px 5px 20px;
            @include media-breakpoint-up(md) {
                padding: 35px 50px 5px 35px;
            }
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &-header {
            padding: 20px;
            @include media-breakpoint-up(md) {
                padding: 35px;
            }
        }
        &-footer {
            padding: 15px 20px 20px;
            @include media-breakpoint-up(md) {
                padding: 30px 35px;
            }
        }
        &-title {
            line-height: 1.35;
            letter-spacing: normal;
            @include font-size(18);
            @include media-breakpoint-up(md) {
                @include font-size(20);
            }
        }
        &-meta {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .meta-block {
                margin: 0;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                &:last-child {
                    margin-right: 0;
                }
            }
            .meta-icon {
                .icon {
                    margin-top: -3px;
                    margin-right: 5px;
                    fill: $turquoise;
                    @include wh-size(11px);
                    @include media-breakpoint-up(md) {
                        margin-right: 8px;
                    }
                }
            }
            .meta-text {
                color: #878787;
                letter-spacing: .025rem;
                @include font-size(11);
                @include media-breakpoint-up(md) {
                    @include font-size(14);
                }
            }
        }
        &-tag,
        &-sector {
            top: 0;
            right: 0;
            color: $white;
            line-height: 1;
            padding: 6px 8px;
            text-align: center;
            position: absolute;
            letter-spacing: .025rem;
            border-radius: 0 0 0 5px;
            background-color: $dark-blue;
            font-weight: 400;
            @include font-size(11);
            @include media-breakpoint-up(md) {
                @include font-size(12);
            }
        }
        &-sector {
            color: $white;
            background-color: $turquoise;
        }
        &.leading {

        }
        &.side {
            margin-top: 26px;
            padding-bottom: 34px;
            border-bottom: 1px solid darken($off-white, 10%);
            .latest-post-body,
            .latest-post-header,
            .latest-post-footer {
                padding: 0;
            }
            .latest-post-title {
                line-height: 1.5;
                margin-bottom: 0;
                letter-spacing: normal;
                @include font-size(16);
            }
            .latest-post-meta {
                margin-bottom: 15px;
                letter-spacing: .025rem;
                justify-content: flex-start;
                span {
                    color: #878787;
                    margin-right: 26px;
                    @include font-size(13);
                    &:last-child {
                        margin-right: 0;
                        color: $turquoise;
                    }
                }
            }                    
        }
        &.resource {
            &-format-video {
                .post-body {
                    padding: 35px 35px 35px 35px;
                }
                .post-video {
                    overflow: hidden;
                    height: 225.89px;
                    position: relative;
                    &-embed {
                        height: 100%;
                    }
                    &-player {
                        height: 100%;
                        object-fit: cover;
                    }
                    &-play-button {
                        position: absolute;
                        height: 41px;
                        width: 41px;
                        display: block;
                        text-align: center;
                        top: 50%;
                        left: 50%;
                        margin-top: -20px;
                        margin-left: -20px;
                        svg {
                            @include transition;
                        }
                    }
                    @include hover() {
                        .post-video-play-button {
                            svg {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
            &-format-brochure {
                .post-body {
                    padding: 35px 50px 5px 35px;
                }
            }
        }
    }
}

.single-article {
    .post {
        margin-bottom: 80px;
        margin-bottom: 40px;
        &-header {

        }
        &-content {
            p {
                font-weight: 300;
                a {
                    word-wrap: break-word;
                }
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
        &-categories {
            color: #757782;
            margin-bottom: 1rem;
            text-transform: uppercase;
            @include font-size(18);
        }

        &-title {
            margin: 0px 0px 1rem;
            @include font-size(46);
        }
        &-meta {
            padding: 0;
            margin: 0 0 1rem;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .meta-block {
                margin: 0 10px;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
            }
            .meta-icon {
                .icon {
                    fill: #757782;
                    margin-top: -3px;
                    margin-right: 5px;
                    @include wh-size(14px);
                }
            }
        }
        &-featured-media {
            overflow: hidden;
            img {
                max-width: 100%;
                height: auto;
            }
        }
        &-author {
            padding: 35px;
            margin-top: 40px;
            text-align: left;
            display: flex;
            justify-content: space-between;
            background-color: $off-white;
            &-img {
                max-width: 100px;
            }
            &-avatar {
                max-width: 100px;
                margin-right: 35px;
                align-self: center;
            }
            &-info {

            }
            &-title {
                margin-bottom: 10px;
                letter-spacing: .05em;
                text-transform: uppercase;
                @include font-size(16);
            }
            &-bio {
                overflow: hidden;
                p {
                    @include font-size(16);
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            &-link {
                margin-top: 1rem;
            }
        }
        &-nav-links {
            margin-top: 40px;
        }
        &-comments {
            margin-top: 40px;
            text-align: left;
            &-header {
                text-align: center;
            }
            &-reply-title {
                font-size: 3rem;
                margin-bottom: 40px;
            }
            .comment {
                margin: 0 0 40px;
                &-avatar {
                    float: left;
                    max-width: 100px;
                    margin-right: 20px;
                    margin-top: 10px;
                }
                &-meta {
                    overflow: hidden;
                }
                &-cite,
                &-cite a,
                &-datetime {
                    color: initial;
                }        
                &-awaiting-moderation {
                    overflow: hidden;
                }
                &-content {
                    padding: 10px 0;
                    overflow: hidden;
                    p {
                        @include font-size(16);
                        &:last-child {
                            margin-bottom: 0;
                        }
                        a {
                            color: lighten($muddy-green, 10%);
                            @include hover-focus() {
                                color: darken($muddy-green, 25%);
                                text-decoration: none;
                            }
                        }
                    }
                }
                &-footer {
                    padding: 0 100px;
                }
                &-reply-link {

                }
                & > .comment {
                    margin-left: 40px;
                }
                &-form {
                    .log-out {
                        float: right;
                        margin-top: 10px;
                    }
                    p.comment-notes,
                    p.logged-in-as {
                        margin: -2rem 0 3rem 0;
                        text-align: center;
                        .required {
                            color: red;
                        }
                    }
                }
                &-reply-title {
                    font-size: 3rem;
                    text-align: center;
                    margin-bottom: 40px;
                }
                &-respond {
                    margin-top: 40px;
                    .comment-reply-title {
                        small {
                            display: block;
                            text-transform: none;
                            line-height: 0;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}