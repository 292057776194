::selection {
    /* WebKit/Blink Browsers */
    background: $turquoise;
}
::-moz-selection {
    /* Gecko Browsers */
    background: $turquoise;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    letter-spacing: -.025em;
}

b, strong {
    color: darken($body-color, 10%);
    font-family: 'GreycliffCF-Bold';
}

//--------------

.section-title {
    line-height: 1;
    margin-bottom: 4rem;
}

.display-5 {
    font-size: $display5-size;
}

.display-6 {
    font-size: $display6-size;
}

//--------------

.close-modal {
    opacity: 1;
    z-index: 1051;
    top: 16px;
    right: 32px;
    font-size: 40px;
    color: $white;
    display: block;
    position: fixed;
    text-shadow: none;
    font-family: $font-family-sans-serif;
    @include hover-focus() {
        outline: none;
        opacity: .8;
        color: $white;
    }
}

.modal {
    &-header {
        text-align: center;
        align-items: center;
        flex-direction: column;
    }
    &-content {
        //box-shadow: $modal-content-box-shadow-xs;
    }
}

//--------------

.rect-outer {
    position: relative;
    .rect-inner {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
    }
}

//--------------

.back-img {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
}

//--------------

.img-duplicate {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    @include media-breakpoint-down(sm) {
        background-image: none !important;
    }
    img {
        min-width: 100%;
        max-width: 100%;
        display: block;
        @include media-breakpoint-up(md) {
            opacity: 0;
            min-height: 100%;
        }
    }
}

//--------------

.info-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
        font-size: 18px;
        font-weight: 300;
        position: relative;
        margin-bottom: 1rem;
        padding-left: 3rem;
        &:last-child {
            margin-bottom: 0;
        }
        &:before {
            font-size: 24px;
            color: $turquoise;
            content: '\f192';
            font-weight: 900;
            font-family: 'Font Awesome 5 Free';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
        }
        a {
            color: $body-color;
            &:hover {
                color: $charcoal;
                text-decoration: none;
                @include transition();
            }
        }
    }
    li.address:before {
        content: '\f3c5';
    }
    li.telephone:before {
        content: '\f095';
    }
    li.email:before {
        content: '\f0e0';
    }
    li.facebook:before {
        font-family: 'Font Awesome 5 Brands';
        content: '\f09a';
    }
    li.linkedin:before {
        font-family: 'Font Awesome 5 Brands';
        content: '\f0e1';
    }
    li.twitter:before {
        font-family: 'Font Awesome 5 Brands';
        content: '\f099';
    }
    li.directions:before {
        content: '\f3c5';
    }
    li.share {
        .fa-stack {
            width: 2em;
        }
    }
    li.share:before {
        content: '\f1e0';
    }
    li.sterling {
        font-weight: 400;
        padding-left: 2rem;
        @include font-size(16);
        &:before { 
            content: '\f154';
            @include font-size(18);
        }
    }
}

//--------------

.tick-list {
    list-style: none;
    padding-left: 0;
    li {
        line-height: initial;
        position: relative;
        margin-bottom: 12px;
        padding-left: 2.5rem;
        &:last-child {
            margin-bottom: 0;
        }
        &:before {
            @include font-size(24);
            font-family: 'Font Awesome 5 Free';
            content: '\f14a';
            display: inline-block;
            color: $turquoise;
            position: absolute;
            top: 9;
            left: 0;
        }        
    }
    &.alt-check {
        li {
            padding-left: 1.875rem;
            &:before {
                content: '\f00c';
                font-weight: 900;
                @include font-size(18);
            }
        }
    }
}

//--------------

.accordion {
    .acc-row {
        padding: 0px 20px;
        border-radius: 5px;
        margin-bottom: 25px;
        border: 1px solid #ddd;
        box-shadow: 0 12px 8px -10px rgb(237 235 235 / 80%), inset 0 0 0 3px rgb(219 148 0 / 0%);
        @include media-breakpoint-down(sm) {
            margin-bottom: 15px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        @include media-breakpoint-down(sm) {
            padding: 0 30px 0 15px;
        }
        .acc-header {
            height: 60px;
            @include media-breakpoint-down(sm) {
                height: auto;
                display: flex;
                align-items: center;
                position: relative;
                padding: 10px 0;
                width: 100%;
            }
            .topic-title,
            .topic-title a {
                font-size: 16px;
                color: $charcoal;
                line-height: 60px;
                font-family: 'GreycliffCF-DemiBold';
                &:hover {
                    text-decoration: none;
                }
                @include media-breakpoint-down(sm) {
                    line-height: 1.2;
                    font-size: 1.0625rem;
                }
            }
            .topic-title a {
                display: block;
                position: relative;
                @include media-breakpoint-down(sm) {
                    position: static;
                }
                &:before {
                    content: '\f068';
                    position: absolute;
                    font-weight: 900;
                    font-family: 'Font Awesome 5 Free';
                    top: 0;
                    right: 0;
                    color: $primary;
                    @include media-breakpoint-down(sm) {
                        top: 50%;
                        margin-top: -9px;
                        right: -18px;
                    }
                }
                &.collapsed:before {
                    content: '\f067';
                }
            }
        }  
        .acc-body {
            padding: 0 0 20px 0;
            line-height: 1.69;
            @include media-breakpoint-down(sm) {
                padding: 10px 0 20px 0;
            }
            p {
                @include media-breakpoint-down(sm) {
                    font-size: .875rem;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }   
    }
    &.accordion-limited {
        margin: 0 auto;
        max-width: 768px;
    }
}

//--------------

.video {
    position: relative;
    .video-mask {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .video-controls {
            width: 80px;
            height: 80px;
            font-size: 28px;
            line-height: 80px;
            text-align: center;
            background-color: $white;
            .video-play {
                display: block;
            }
        }
    }
    &-description {
        padding: 20px 40px;
        font-size: 14px;
        line-height: 150%;
        background: $charcoal;
        color: $white;
    }
}

//--------------

.location-details {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
        display: flex;
        margin-bottom: 25px;
        font-weight: bold;
        &:last-child {
            margin-bottom: 0;
        }
        .fas {
            color: $turquoise;
            font-size: 32px;
            width: 60px;
            line-height: 1.5;
        }
        a {
            color: $turquoise;
            text-decoration: none;
            margin-top: 10px;
        }
        .schedule {
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 60px);
            span {
                color: #424242;
                margin-bottom: 2px;
                &:nth-child(odd) {
                    width: 110px;
                }
                &:nth-child(2n) {
                    width: calc(100% - 110px);
                }
            }
        }
    }
}

//--------------

.split {
    position: relative;
    & .split-bg {
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        display: none;
        position: absolute;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @include media-breakpoint-up(lg) {
            display: block;
        }
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 100%;
        }
        &.gradient {
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 100%;
                height: 100%;
                background-image: linear-gradient(to right, #FFFFFF 0%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            }
        }
        &.overlay-none {
            &:before {
                display: none;
            }
        }
        &.overlay-light {
            &:before {
                background-color: rgba($off-white, .3) !important;
            }
        }
        &.overlay-dark {
            &:before {
                background-color: rgba($charcoal, .25) !important;
            }
        }
    }
    & .split-content-box {
        padding: 10% 14% 14% 14%;
        @include media-breakpoint-up(md) {
            //margin-left: 85px;
        }
        & h5 {
            font-size: 15px;
            padding-left: 30px;
            border-left: 4px solid $yellow;
            line-height: 28px;
            font-weight: 500;
            margin-bottom: 40px;
            color: $body-color;
        }
    }
    &.reversed {
        & .split-bg {
            left: auto;
            right: 0;
        }
        & .split-content-box {
            @include media-breakpoint-up(md) {
                //margin-left: auto;
                //margin-right: 85px;
            }
        }
    }
    &-alt {
        & .split-bg {
            width: 41.6666666667%;
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 100%;
                height: 100%;
                background-color: rgba($headings-color, .15);
            }
        }
    }
}

//--------------

.quick-why {
    z-index: 3;
    position: relative;
    .why {
        padding: 60px 14% 60px 14%;
        &-icon {
            @include font-size(64);
        }
        &-title {
            //@include font-size(21);
        }
        &-text {
            //@include font-size(15);
        }
        &-even {
            @include media-breakpoint-up(lg) {
                border-left: 1px solid rgba($black, .15);
                border-right: 1px solid rgba($black, .15);
            }
        }
    }
}

//--------------

.block-panels {
    .panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 60px 14% 60px 14%;
        .panel-icon {
            @include font-size(64);
        }
        .panel-title {
            line-height: 1;
            margin-top: 40px;
        }
        .panel-text {
            margin-top: 10px;
        }
        &.panel-even {
            @include media-breakpoint-up(lg) {
                border-left: 1px solid rgba($charcoal, .1);
                border-right: 1px solid rgba($charcoal, .1);
                &.panel-last {
                    border-right: none;
                }
            }
        }
        &.panel-subscribe {
            padding: 0 13.5% 9 13.5%;
            .panel-title {
                margin-top: 0;
                line-height: 1;
                @include font-size(48);
            }
            .panel-form {
                margin-top: 30px;
            }
        }
        &.panel-cover {
            position: relative;
            padding: 80px 13.5% 80px 13.5%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            &:before {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                content: '';
                z-index: 10;
                opacity: .85;
                display: block;
                position: absolute;
                background-color: rgba($turquoise,.85);
            }
            .panel-overlay {
                z-index: 20;
                position: relative;
                .panel-title {
                    margin-top: 0;
                    line-height: 1;
                }
            }
        }
    }
}

//--------------

.rentals {
    padding-top: 100px;
}

//--------------

#CDSWIDSSP .withBorder {
    border: 1px solid $border-color !important;
}

//--------------

.basic-content {
    .better-times {
        line-height: 1;
    }
}

//--------------

.showcase {
    &:before {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
        height: 500px;
        background-size: cover;
        background-image: url('../img/content/misc/section/cyclescheme.jpg');
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
    }
    &:after {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        z-index: 6;
        height: 500px;
        background-color: rgba($charcoal, .65);
        position: absolute;
    }
    .container {
        z-index: 10;
        position: relative;
    }

    &.bike-rentals {
        &:before {
            background-image: url('../img/content/hero/image-copyright-52-1.jpg');
        }
    }
}

//--------------

.panels {
    .panel {
        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &-inner,
        .overlay {
            text-align: center;
        }
        &-inner {
            height: 307px;
            position: relative;
            transition: box-shadow .3s;
            .panel-title {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
            }
            .panel-title {
                z-index: 5;
                background: rgba(0,0,0,.3);
                color: $white;
                font-size: 28px;
                padding: 30px 15px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                overflow: hidden;
            }
            .overlay {
                opacity: 0;
                z-index: 10;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                height: 100%;
                transition: opacity .15s;
                &-inner {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    background: $white;
                    padding: 20% 7.5%;
                }
                &-icon {
                    display: block;
                    font-size: 60px;
                    margin-bottom: 20px;
                }
                &-title {
                    font-size: 18px;
                    text-transform: uppercase;
                    color: $reddish;
                    margin-bottom: 0;
                }
                &-content {
                    margin: 8px 0;
                    line-height: 1.6em;
                    font-size: 1rem;
                }
            }
            &:hover {
                box-shadow: 0 0 30px rgba(0,0,0,.4);
                .overlay {
                    opacity: 1;
                }
            }                
        }
    }
}

//--------------

.contact {
    padding: 180px 25px 40px;
    @include media-breakpoint-up(lg) {
        min-height: 822px;
        padding: 180px 0 100px;
    }
    &-block {
        padding: 0 45px;
    }
    &-intro {
        &-title {
            color: $white;
            margin-bottom: 20px;
            letter-spacing: .025rem;
            @include font-size(24);
            @include media-breakpoint-up(lg) {
                @include font-size(44);
            }
        }
        &-text {
            color: $white;
            font-weight: 300;
            line-height: 1.63;
            margin-bottom: 30px;
            @include font-size(16);
        }
    }
    &-form {
        .form-group {
            margin-bottom: 36px;
        }
        .form-control {
            padding: 0;
            color: $white;
            border: none;
            resize: none;
            border-radius: 0;
            box-shadow: none;
            line-height: 1.5;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            background-color: transparent;
            border-bottom: 1px solid rgba(255,255,255,.47);
            @include font-size(16);
            @include placeholder() {
                opacity: .8;
                color: $off-white;
            }
            &:focus {
                outline: 0;
                box-shadow: none !important;
                border-bottom: 1px solid $turquoise;
                @include placeholder() {
                    opacity: 1;
                }
            }
        }
        .btn-send {
            min-width: 100%;
            @include media-breakpoint-up(lg) {
                min-width: 170px;
            }
            .fas {
                color: #212529;
            }
        }
    }
    &-details {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: 40px;
        padding-bottom: 40px;
        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }
        address {
            color: $white;
            font-weight: 300;
            line-height: 1.27;
            margin-bottom: 40px;
            letter-spacing: .025rem;
            @include font-size(18);
            @include media-breakpoint-up(lg) {
                @include font-size(22);
            }
            h6 {
                opacity: .75;
                color: $white;
                font-weight: 300;
                line-height: 1.63;
                @include font-size(16);
            }
            a {
                color: $white;
                &:first-child {
                    margin-bottom: 8px;
                }
            }
        }
    }
}

//--------------

.nearest-locations {
    padding: 0 4rem;
    list-style: none;
    margin: 40px auto 0;
    .location {
        padding: 40px 0;
        border-top: 1px solid $border-color;
        > div {
            display: flex;
            justify-content: space-between;
            @include media-breakpoint-down(md) {
                display: block;
                justify-content: unset;
            }
            > div {
                width: 100%;
            }
        }
    }
    .photo-col {
        max-width: 400px;
        @include media-breakpoint-down(md) {
            display: block;
            width: 100%;
            margin-bottom: 30px;
            max-width:none;
        }            
        a {
            transition: 0.5s;
            &:hover {
                opacity: 0.7;
            }
        }
    }
    .desc-col {
        line-height: 1.5;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(md) {
            display: block;
            width: 100%;
        }
        .title-address-col {
            width: 60%;
            padding: 0 0 0 60px;
            @include media-breakpoint-down(md) {
                width: 100%;
                padding: 0;
                margin-bottom: 30px;
            }
            .title {
                h4 {
                    font-size: 26px;
                    margin-bottom: 5px;
                    //text-transform: uppercase;
                }
            }
            .email,
            .phone,
            .address {
                font-size: 18px;
            }
            .address {
                line-height: 1.4;
                margin-bottom: 8px;
            }
            .address-info-col {
                padding: 4px 0 0;
                line-height: 1.5;
            }
        }
        .webpage-col {
            width: 40%;
            display: flex;
            justify-content: flex-end;
            @include media-breakpoint-down(md) {
                width: 100%;
                display: block;
                justify-content: unset;
            }                
        }
    }
}

//--------------

.tabs {
    .nav-tabs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        min-height: 67px;
        border-bottom: none;
        &.main-nav-tabs {
            @include media-breakpoint-down(md) {
                display: none;
            }
            li {
                width: 25%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                transition: .3s;
                overflow: hidden;
                position: relative;
                &:before {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 28px 24px;
                    border-color: transparent transparent $off-white;
                    transition: .3s;
                    bottom: -101%;
                    transform: translateX(-50%);
                    left: 50%;
                    content: '';
                    position: absolute;
                }
                a {
                    color: $white;
                    display: block;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    padding: 80px 30px;
                    font-size: 24px;
                    margin: 12px 0 22px;
                    line-height: 120%;
                    text-transform: uppercase;
                    h2 {
                        color: inherit;
                        font-size: inherit;
                    }
                    [class^=icon-] {
                        font-size: 70px;
                        display: block;
                        margin-bottom: 10px;
                        &:before {
                            display: block;
                            width: 100%
                        }
                    }                    
                }
                &:first-child {
                    background: $turquoise;
                    &:hover {
                        background: darken($turquoise, 10%);
                    }
                }
                &:nth-child(2) {
                    background: $yellow;
                    &:hover {
                        background: darken($yellow, 10%);
                    }
                }
                &:nth-child(3) {
                    background: $reddish;
                    &:hover {
                        background: darken($reddish, 10%);
                    }
                }
                &:nth-child(4) {
                    background: $logo-grey;
                    &:hover {
                        background: darken($logo-grey, 10%);
                    }
                }
                &.active,
                &:hover {
                    &:first-child,
                    &:nth-child(2) {
                        &:before {
                            border-color: transparent transparent $white;
                        }
                    }
                    a {
                        text-decoration: none;
                    }
                }
                &.active {
                    &:before {
                        bottom: 0;
                    }
                }
                &.has-sticky-nav-tabs {
                    &:before {
                        border-color: transparent transparent $off-white !important;
                    }
                }
            }
        }
        &.sub-nav-tabs {
            background: $off-white;
            align-items: inherit;
            justify-content: center;
            li {
                display: flex;
                justify-content: center;
                @include media-breakpoint-down(lg) {
                    width: 33.3%;
                    text-align: center;
                }
                a {
                    position: relative;
                    padding: 0px 20px;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    color: $logo-grey;
                    text-transform: uppercase;
                    font-size: 14px;
                    @include media-breakpoint-down(lg) {
                        font-size: 15px;
                    }
                    &:before {
                        display: none;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 4px;
                        width: 0;
                        transition: .3s;
                        background: $turquoise;
                    }
                    &:hover {
                        color: $turquoise;
                        text-decoration: none;
                    }
                }
                &.active,
                &:hover {
                    a {
                        color: $logo-grey;
                    }
                }
                &.active {
                    a {
                        &:after {
                            width: 100%;
                        }
                    }
                }
            }
        }
        &.sub-sub-nav-tabs {
            background: 0 0;
        }
    }
    .tab-content > .tab-pane {
        display: block;
        .tab-pane-inner {
            padding: 60px 60px 60px;
            p {
                @include font-size(17);
            }
        }
    }
    .tab-content .tab-content .tab-pane:not(.active) {
        display: none
    }
    .tab-content .tab-pane.fade {
        opacity: 1
    }
    @include media-breakpoint-up(md) {
        .tab-content .tab-pane {
            display: none;
        }
        .tab-content .tab-pane.active,
        .tab-content .tab-pane.active > .tab-pane {
            display: block;
            opacity: 1;
        }
        .tab-content .tab-pane.collapse {
            height: auto !important
        }
    }
    .tab-ttl {
        font-size: 24px;
        letter-spacing: -1.3px;
        text-transform: uppercase;
        color: #58595b;
        text-align: center;
        margin: 20px 0;
        @include media-breakpoint-up(sm) {
            font-size: 45px;
        }
    }
    .tab-title {
        font-size: 24px;
        @include media-breakpoint-up(sm) {
            font-size: 45px;
        }        
    }

    .tab-pane.active .panel-heading a:not([aria-expanded=false]) [class^=icon-arrow]:before {
        content: "\E808"
    }

    .panel-heading {
        position: relative;
        border-top: 1px solid #e8e8e8;
    }

    @media(min-width: 801px) {
        .panel-heading {
            display: none;
        }
    }

    .panel-heading a {
        padding: 10px 35px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        font-size: 24px;
        text-transform: uppercase;
        &:hover {
            text-decoration: none;
        }
        span {
            @media(max-width: 800px) {
                display: none;
            }
        }
    }

    @media(max-width: 550px) {
        .panel-heading a {
            padding: 10px;
            font-size: 20px
        }
    }

    .panel-heading a br {
        display: none
    }

    .panel-heading a .icon {
        font-size: 34px;
        color: #ee6845;
        display: inline-block;
        margin-right: 23px
    }

    @media(max-width: 350px) {
        .panel-heading a .icon {
            margin-right:5px;
            font-size: 30px
        }
    }

    .panel-heading a .icon.icon-assets {
        font-size: 28px
    }

    .panel-heading a [class^=icon-arrow] {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        color: #ee6845;
        right: 35px
    }

    .panel-heading a:not(.collapsed) [class^=icon-arrow]:before {
        content: "\E808"
    }
}

//--------------

.press-contacts {
    padding: 50px;
    color: $white;
    text-align: center;
    .container {
        max-width: 1000px;
    }
    .main-ttl-4 {
        font-style: normal;
        margin-bottom: 10px;
        color: $white;
        font-size: 30px;
        letter-spacing: 0;
    }
    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .contact {
            padding-top: 20px;
            padding-bottom: 20px;
            font-weight: 700;
            @include media-breakpoint-up(sm) {
                display: flex;
                text-align: left;
                align-items: center;
            }
            .img {
                border-radius: 50%;
                width: 140px;
                height: 140px;
                margin-right: 30px;
                @include media-breakpoint-down(sm) {
                    margin: auto;
                }
            }
            .info {
                padding: 5px 0 10px;
                @include media-breakpoint-up(sm) {
                    width: calc(100% - 283px);
                }
            }
            h4 {
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 8px;
                font-family: $font-family-base;
            }
            a {
                display: inline-block;
                margin-bottom: 8px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }        
    }
}

//--------------

.recent-articles {
    .articles {
        .article {
            border-bottom: 1px solid $border-color;
            &:last-child {
                border-bottom: none;
            }
            .descr {
                padding-left: 60px;
                @include media-breakpoint-down(lg) {
                    padding-left: 0;
                    padding-top: 40px;
                }
                .tab-title {
                    font-size: 40px;
                    line-height: 1;
                }
                .date {
                    margin: 0 0 15px;
                    @include media-breakpoint-down(lg) {
                        text-align: center;
                        margin: 10px 0 20px;
                    }
                }
                .excerpt {
                    line-height: 1.5;
                    .read-more {
                        color: $turquoise;
                        display: block;
                    }
                }                
            }            
        }
    }    
}

//--------------

.articles {
    .article {
        margin-bottom: 3rem;
        padding-bottom: 3rem;
        border-bottom: 1px solid $border-color;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
        .title {
            font-size: 42px;
            line-height: 1;
        }
        .date {
            margin: 25px 0;
            @include font-size(16);
        }
        .excerpt {
            line-height: 1.5;
            .read-more {
                color: $turquoise;
                display: block;
                text-decoration: none;
            }
        }
        .image {

        }
        .quote {
            padding: 35px;
            background-color: $off-white;
            border-left: 4px solid $turquoise;
            position: relative;
            margin: 110px auto 35px;
            line-height: 150%;
            &:before {
                content: '\201D';
                color: $turquoise;
                font-size: 90px;
                position: absolute;
                top: -26px;
                left: 50%;
                transform: translateX(-50%);
                font-family: $headings-font-family;
            }
            p+p {
                padding-top: 24px
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
        .author {
            margin: 30px auto;
            text-align: center;
            a {
                color: #ee6845;
                font-style: italic;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

//--------------

.single-news {
    .article {
        margin: 0 auto;
        max-width: 800px;
        .quote {
            padding: 10px 35px;
            position: relative;
            margin: 0 auto 80px;
            line-height: 150%;
            &:before {
                content: '\201D';
                color: $turquoise;
                font-size: 90px;
                position: absolute;
                top: -26px;
                left: 50%;
                transform: translateX(-50%);
                display: none;
            }
            &:after {
                content: '\201D';
                color: $turquoise;
                font-size: 120px;
                position: absolute;
                top: 30px;
                right: -2px;
                display: none;
            }
            p {
                font-style: normal;
            }
            p+p {
                padding-top: 24px;
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
        .author {
            margin: 40px auto;
            text-align: center;
            .logo {
                text-align: center;
            }
            a {
                color: $turquoise;
                font-style: italic;
                display: block;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }   
    .main-title {
        font-size: 45px;
    }
    .content {
        p+p {
            padding-top: 24px;
        }        
    }
    .date {
        color: #787878;
        margin: 25px 0;
    }
    .image {
        margin: 50px 0px;
        .caption {
            padding: 20px 30px;
            font-size: 16px;
            line-height: 150%;
            background: $yellow;
            color: $logo-grey;
        }
    }
    .related {
        .list {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            justify-content: space-evenly;
            .item {
                text-align: left;
                margin: 15px 0 0 0;
                max-width: calc(50% - 20px / 2);
                .btn {
                    &:hover {
                        text-decoration: none;
                    }
                    .text-truncate {
                        max-width: 290px;
                    }
                }
            }
        }
    }
}

//--------------

.introduction {
    margin-bottom: 4rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid $border-color;
}

//--------------

.faqs {
    .faq {
        padding: 24px;
        [class^=icon-] {
            font-size: 76px;
            display: block;
            color: #787a7d;
            &:before {
                width: 100%;
            }
        }
        h4 {
            color: $turquoise;
            margin: 15px 0;
            font-size: 24px;
        }
        p {
            margin-top: 10px;
        }        
    }
}

//--------------

.archive-info {
    z-index: 99;
    color: $white;
    overflow: visible;
    .main-title {
        text-align: left;
    }
    p {
        &:last-child {
            margin-bottom: 0px;
        }
    }
    .col-lg-5 {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 30px;
        .image {
            width: 100%;
            padding-top: 112%;
            margin-bottom: -108px;
        }
    }
    .col-lg-6:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    @include media-breakpoint-down(md) {
        .wrap-img {
            position: absolute;
            top: 100%;
            max-width: 274px;
            width: 100%;
            left: 50%;
            transform: translateX(-50%)
        }
    }
    em,
    i {
        font-style: italic
    }

    b,
    strong {
        font-weight: 700
    }
}

//--------------

.archive-filter {
    background: $off-white;    
    body.filter-fixed & {        
        padding-bottom: 90px;
        .filters {
            width: 100%;
            position: fixed;
            z-index: 999;
            top: 47px;
            left: 0
        }        
    }
    .filters {
        text-align: center;
        margin-bottom: 0;
        width: 100%;
        li.filter {
            display: inline-block;
            padding: 15px 25px;
            position: relative;
            text-transform: uppercase;
            color: #58595b;
            font-size: 16px;
            transition: color .3s;
            cursor: pointer;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                top: calc(100% - 4px);
                height: 0;
                transition: height .3s;
                background: #ee6845
            }
            &:hover {
                color: $reddish;
            }
            &.active {
                font-weight: 700;
                color: $reddish;
            }
            &.active:before,
            &:hover:before {
                height: 4px
            }
        }
        &.bg-reddish {
            li {
                color: $white;
            }
        }
    }
}

//--------------

.feature-popups {
    text-align: center;
    overflow: visible;
    .interact {
        margin: 0 auto;
        max-width: 777px;
        position: relative;
        img {
            max-width: 100%;
        }
        .dot {
            position: absolute;
        }
        .popup {
            display: none;
            position: absolute;
            width: 340px;
            max-width: 100%;
            border: 1px solid #ccc;
            background: #fff;
            transform: translate(-144px, -100px);
            margin: -15px 0 0 -15px;
            z-index: 10;
            @include media-breakpoint-down(lg) {
                top: 50% !important;
                left: 50% !important;
                transform: translate(-50%,-50%) !important;
                margin: 0;
            }
            .popup_image {
                width: 100%;
                padding-top: 70%;
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: cover;
                .icon-close {
                    top: 15px;
                    right: 15px;
                    position: absolute;
                    color: $turquoise;
                    transition: color .3s;
                    cursor: pointer;
                    &:hover {
                        //color: $reddish;
                    }
                }
            }
            .popup_content {
                padding: 15px;
                h3 {
                    font-size: 1rem;
                    font-family: $font-family-base;
                    text-transform: uppercase;
                    font-weight: 700;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

//--------------

.dot {
    background-color: #15babd;
    cursor: pointer;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: .7s;
    opacity: .8;
    transform: translate(-50%,-50%) scale(0);

    &:first-child {
        transition-delay: .15s
    }

    &:first-child:before {
        animation-delay: .15s
    }

    &:nth-child(2) {
        transition-delay: .3s
    }

    &:nth-child(2):before {
        animation-delay: .3s
    }

    &:nth-child(3) {
        transition-delay: .45s
    }

    &:nth-child(3):before {
        animation-delay: .45s
    }

    &:nth-child(4) {
        transition-delay: .6s
    }

    &:nth-child(4):before {
        animation-delay: .6s
    }

    &:nth-child(5) {
        transition-delay: .75s
    }

    &:nth-child(5):before {
        animation-delay: .75s
    }

    &:nth-child(6) {
        transition-delay: .9s
    }

    &:nth-child(6):before {
        animation-delay: .9s
    }

    &:nth-child(7) {
        transition-delay: 1.05s
    }

    &:nth-child(7):before {
        animation-delay: 1.05s
    }

    &:nth-child(8) {
        transition-delay: 1.2s
    }

    &:nth-child(8):before {
        animation-delay: 1.2s
    }

    &:nth-child(9) {
        transition-delay: 1.35s
    }

    &:nth-child(9):before {
        animation-delay: 1.35s
    }

    &:nth-child(10) {
        transition-delay: 1.5s
    }

    &:nth-child(10):before {
        animation-delay: 1.5s
    }

    &:before {
        width: 34px;
        height: 34px;
        transition: .5s;
        border-radius: 50%;
        border: 2px solid #15babd;
        animation: pulse 1.9s;
        animation-iteration-count: infinite;
        transform: translate(-50%,-50%);
        display: none;
    }

    &:after,
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%
    }

    &:after {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #15babd;
        transition: transform .3s,opacity 0s;
        transform: translate(-50%,-50%) scale(0);
    }

    .seen & {
        transform: translate(-50%,-50%) scale(1)
    }

    .seen &:before {
        display: block
    }

    .seen &:after {
        transform: translate(-50%,-50%) scale(1)
    }

    .seen &:hover:before {
        border-color: transparent
    }

    .seen &:hover:after {
        transform: translate(-50%,-50%) scale(1.4);
        transition-delay: 0s
    }
}

//--------------

.overview {
    .block-title {
        font-size: 2.25rem;
        letter-spacing: -.025rem;
    }
    .block-subtitle {
        font-size: 1.0625rem;
    }
    p {
        font-size: 0.9375rem;
    }
}

//--------------

.features {
    .block-title {
        font-size: 2.25rem;
        letter-spacing: -.025rem;
    }
}

//--------------

.nav-steps-wrap {
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 99;
    position: absolute;
    .nav-steps {
        list-style: none;
        counter-reset: steps;
        li {
            line-height: 50px;
            counter-increment: steps;
            &.active a {
                text-decoration: underline;
            }
            &::before {
                content: counter(steps);
                margin-right: 0.5rem;
                background: $turquoise;
                color: $charcoal;
                width: 1.2em;
                height: 1.2em;
                @include font-size(15);
                border-radius: 50%;
                display: inline-block;
                text-align: center;
                line-height: 1.2em;
            }
            a {
                color: $white;
            }
        }
    }
}

//--------------

.testimonials {
    padding: 80px 0 100px;
    body.post-type-archive-testimonial & {
        padding: 0;
    }
    .testimonial {
        padding: 60px;
        display: flex;
        flex-direction: column;
        body.post-type-archive-testimonial & {
            padding: 50px;
        }
        .testimonial-header {
            .testimonial-icon {
                color: $turquoise;
                margin-bottom: 30px;
                @include font-size(32);
            }
            .testimonial-author {
                @include font-size(17);
                letter-spacing: normal;
            }
        }
        .testimonial-content {
            margin-top: 20px;
            margin-bottom: 20px;
            .testimonial-quote {
                margin-bottom: 0;
            }
        }
        .testimonial-footer {
            margin-top: auto;
            .testimonial-rating {
                i {
                    color: #fbc900;
                    @include font-size(15);
                }
            }
        }
    }
}

//--------------

.wp-video { width: 100% !important }
.wp-video video { width: 100% !important; height: 100% !important; }

.mejs-controls {
    transition: all 0.5s ease;
    body.home &,
    body.page-template-tpl-own-a-business &,
    body.page-template-tpl-be-an-investor & {
        opacity: 0 !important;
    }
}

.mejs-container:hover .mejs-controls {
    opacity: 1 !important;
}

.mejs-overlay-buttonx {
    display: none !important;
    visibility: hidden !important;
}
