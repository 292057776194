.woocommerce-account {

    .main-ttl-1 {
        //display: none;
    }
}

@include media-breakpoint-up(md) {
    .woocommerce-MyAccount-navigation {
        width: 17.6470588235%;
        float: left;
        margin-right: 5.8823529412%;
    }
    .woocommerce-MyAccount-content {
        width: 76.4705882353%;
        float: right;
        margin-right: 0;
    }
}

.woocommerce-MyAccount-navigation {
    ul {
        margin-left: 0;
        padding-left: 0;
        li {
            list-style: none;
            border-bottom: 1px solid $border-color;
            position: relative;
            &:last-child {
                border-bottom: none;
            }
            a {
                padding: .875em 0;
                display: block;
                color: $charcoal;
                font-weight: 600;
                &:hover {
                    text-decoration: none;
                    color: $turquoise;
                }
                &:before {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    line-height: 1;
                    vertical-align: -.125em;
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 900;
                    line-height: inherit;
                    vertical-align: baseline;
                    content: '\f15c';
                    line-height: 1.618;
                    margin-left: 0.5407911001em;
                    width: 1.41575em;
                    text-align: right;
                    float: right;
                    opacity: 1;
                    color: $turquoise;
                }
                &:hover:before {
                    opacity: 1;
                }
            }
            &.is-active {
                a {
                    color: $turquoise;
                    &:before {
                        opacity: 1;
                    }
                }
            }
            &.woocommerce-MyAccount-navigation-link--dashboard {
                a:before {
                    content: '\f3fd';
                }
            }
            &.woocommerce-MyAccount-navigation-link--orders {
                a:before {
                    content: '\f291';
                }
            }
            &.woocommerce-MyAccount-navigation-link--downloads {
                a:before {
                    content: '\f1c6';
                }
            }
            &.woocommerce-MyAccount-navigation-link--edit-address {
                a:before {
                    content: '\f015';
                }
            }
            &.woocommerce-MyAccount-navigation-link--payment-methods {
                a:before {
                    content: '\f09d';
                }
            }
            &.woocommerce-MyAccount-navigation-link--edit-account {
                a:before {
                    content: '\f007';
                }
            }
            &.woocommerce-MyAccount-navigation-link--customer-logout {
                a:before {
                    content: '\f2f5';
                }
            }
            &.woocommerce-MyAccount-navigation-link--subscriptions {
                a:before {
                    content: '\f021';
                }
            }
        }
    }
}

.woocommerce-Addresses {
    @include media-breakpoint-up(lg) {
        display: flex;
    }
    > div {
        width: 100%;
        margin-bottom: 30px;
        @include media-breakpoint-up(lg) {
            width: 50%;
            flex: auto;
            margin-bottom: 0;
        }
    }
}

.woocommerce-MyAccount-content {
    form {
        .form-row {
            display: block;
            margin-right: 0;
            margin-left: 0;
            margin-bottom: 1.5rem;
            label {
                display: block;
            }
            label.woocommerce-form-login__rememberme {
                    margin-right: 10px;
            }
            input,
            textarea {
                border: 1px solid $border-color;
                background-color: $off-white;
                padding: 10px 20px;
                color: $body-color;
                &:focus {
                    outline: none;
                    outline-offset: unset;
                }
            }
            input,
            select,
            textarea {
                width: 100%;
            }
            input[type=checkbox],
            input[type=radio] {
                width: auto;
            }
            &.woocommerce-validated {
                input.input-text {
                    box-shadow: inset 2px 0 0 #0f834d;
                }
            }
            &.woocommerce-invalid {
                input.input-text {
                    box-shadow: inset 2px 0 0 #e2401c;
                }
            }
            &.form-row-last {
                margin-right: 0 !important
            }

            &.form-row-wide {
                clear: both;
                width: 100%
            }
            .required {
                text-decoration: none;
                color: $turquoise;
                border-bottom: 0 !important;
            }
            span {
                font-size: 80%;
            }
        }
        .select2-container {
            .select2-selection {
                background-color: $off-white;
                &:focus {
                    outline: none;
                    outline-offset: unset;
                }
            }
            .select2-selection--single {
                border: 1px solid #e4e4e4;
                height: auto;
                border-radius: 0;
                .select2-selection__rendered {
                    padding: 12px 20px;
                    line-height: 1.5;
                    height: calc(1.5em + 0.75rem + 2px);
                    height: auto;
                }
                .select2-selection__arrow {
                    top: 14px;
                }
            }
        }
        fieldset {
            legend {
                font-size: 36px;
                font-family: Oswald, sans-serif;
                letter-spacing: -1.3px;
                text-transform: uppercase;
                font-style: normal;
                text-align: left;
                color: $headings-color;
                margin-bottom: 20px;
            }
        }
    }
}

.woocommerce-orders-table {
    &__row {

    }
    &__cell {
        &-order-actions {
            .button {
                padding: 0.6180469716em 0.875em;
                font-size: 0.875em;
                margin-right: 0.236em;
            }
        }

    }
}

#customer_login {
    @include media-breakpoint-up(lg) {
        display: flex;
    }
    > div {
        width: 100%;
        margin-bottom: 30px;
        @include media-breakpoint-up(lg) {
            width: 50%;
            flex: auto;
            margin-bottom: 0;
        }
    }
    .woocommerce-form-register {
        p:not(.form-row) {
            margin-bottom: 1.5rem;
        }
    }    
}