@font-face {
    font-family: 'GreycliffCF-DemiBold';
    src: url('../webfonts/greycliff/greycliff-cf-demi-bold.eot');
    src: url('../webfonts/greycliff/greycliff-cf-demi-bold.eot?#iefix') format('embedded-opentype'),
             url('../webfonts/greycliff/greycliff-cf-demi-bold.woff2') format('woff2'),
         url('../webfonts/greycliff/greycliff-cf-demi-bold.woff') format('woff'),
         url('../webfonts/greycliff/greycliff-cf-demi-bold.ttf') format('truetype'),
         url('../webfonts/greycliff/greycliff-cf-demi-bold.svg#youworkforthem') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GreycliffCF-Light';
    src: url('../webfonts/greycliff/greycliff-cf-light.eot');
    src: url('../webfonts/greycliff/greycliff-cf-light.eot?#iefix') format('embedded-opentype'),
             url('../webfonts/greycliff/greycliff-cf-light.woff2') format('woff2'),
         url('../webfonts/greycliff/greycliff-cf-light.woff') format('woff'),
         url('../webfonts/greycliff/greycliff-cf-light.ttf') format('truetype'),
         url('../webfonts/greycliff/greycliff-cf-light.svg#youworkforthem') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GreycliffCF-Bold';
    src: url('../webfonts/greycliff/greycliff-cf-bold.eot');
    src: url('../webfonts/greycliff/greycliff-cf-bold.eot?#iefix') format('embedded-opentype'),
             url('../webfonts/greycliff/greycliff-cf-bold.woff2') format('woff2'),
         url('../webfonts/greycliff/greycliff-cf-bold.woff') format('woff'),
         url('../webfonts/greycliff/greycliff-cf-bold.ttf') format('truetype'),
         url('../webfonts/greycliff/greycliff-cf-bold.svg#youworkforthem') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'GreycliffCF-ExtraBold';
    src: url('../webfonts/greycliff/greycliff-cf-extra-bold.eot');
    src: url('../webfonts/greycliff/greycliff-cf-extra-bold.eot?#iefix') format('embedded-opentype'),
             url('../webfonts/greycliff/greycliff-cf-extra-bold.woff2') format('woff2'),
         url('../webfonts/greycliff/greycliff-cf-extra-bold.woff') format('woff'),
         url('../webfonts/greycliff/greycliff-cf-extra-bold.ttf') format('truetype'),
         url('../webfonts/greycliff/greycliff-cf-extra-bold.svg#youworkforthem') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'WC-Mano-Negra-Bta';
    src: url('../webfonts/mano-negra/WC-Mano-Negra-Bta.ttf.woff') format('woff'),
         url('../webfonts/mano-negra/WC-Mano-Negra-Bta.ttf.svg#WC-Mano-Negra-Bta') format('svg'),
         url('../webfonts/mano-negra/WC-Mano-Negra-Bta.ttf.eot'),
         url('../webfonts/mano-negra/WC-Mano-Negra-Bta.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'better_timesregular';
    src: url('../webfonts/better-times/better_times-webfont.woff2') format('woff2'),
         url('../webfonts/better-times/better_times-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.better-times {
    font-family: 'better_timesregular', cursive;
}

.mano-negra {
    font-family: 'WC-Mano-Negra-Bta', cursive;
}