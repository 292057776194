// Embeds

.embed-poster {
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    margin: 0;
    padding: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    position: absolute;
    text-indent: -999em;
    background-size: cover;
    background-position: center;
    transition: opacity 800ms, height 0s;
    transition-delay: 0s, 0s;

    &:before {
        content: "";
        top: 50%;
        left: 50%;
        opacity: .7;
        width: 90px;
        height: 90px;
        position: absolute;
        border-radius: 100%;
        margin: -45px 0 0 -45px;
        border: 5px solid $white;
        transition: border-color 300ms;
    }
    &:after {
        content: '';
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        opacity: .7;
        position: absolute;
        margin: -25px 0 0 -15px;
        border-left: 40px solid $white;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        transition: border-color 300ms;
    }
    &:hover:before, 
    &:focus:before {
        opacity: 1;
    }
    &:hover:after, 
    &:focus:after {
        opacity: 1;
    }
}

.js-embed-responsive-active {
    .embed-poster {
        opacity: 0;
        height: 0;
        transition-delay: 0s, 800ms;        
    }
}
