.paging {
    margin: 30px auto 30px;
    max-width: 200px;
    text-align: center;
    span {
        position: relative;
        font-size: 18px;
        font-weight: 500;
        line-height: 2.44;
        text-align: center;
        color: $muddy-green;
        padding: 0 5px;        
    }
}

.pagination {
    justify-content: center;
    font-weight: 600;
    .page-link {
        border: none;
        background: none;
        color: $muddy-green;
        &:hover {
            background: none;
            color: $turquoise;
        }
        &:focus {
            box-shadow: none;
        }
    }
    .page-numbers {
        color: $muddy-green;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        &.current {
            color: $turquoise;
            border-radius: 50%;
        }
        &:hover {
            text-decoration: none;
            color: $turquoise;
        }
    }
}
