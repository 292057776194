body {
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.cookie {
        padding-bottom: 136px;
        @include media-breakpoint-up(md) {
            padding-bottom: 108px;
        }
    }
    &.modal-open {
        overflow-x: auto;
        overflow-y: hidden !important;
    }
    &.menu-open {
        overflow-y: hidden !important;
    }
    &.scrolled {
        padding-top: 78px;
        @include media-breakpoint-up(sm) {
            padding-top: 88px;
        }
        @include media-breakpoint-up(lg) {
            padding-top: 0;
        }        
    }
}

//--------------

.feature-icon {
    font-size: 24px;
}

//--------------

.padded {
    padding-top: 3rem;
    padding-bottom: 3rem;
    @include media-breakpoint-up(xl) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    &.padded-limiter {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

//--------------

.spaced {
    margin-bottom: 3rem;
    @include media-breakpoint-up(lg) {
        margin-bottom: 100px;
    }
    &-limiter {
        margin-bottom: 80px;
    }
}

//--------------

.container {
    @include media-breakpoint-up(xl) {
        &.container-limiter {
            max-width: 1200px !important;
        }
    }
}

.container-squeezed {
    @extend .container-fluid;
    max-width: 860px;
}

.container-limited {
    @extend .container-fluid;
    max-width: 1260px;
}

//--------------

.row {
    &.slimmed {
        margin-left: -10px;
        margin-right: -10px;
        [class*='col-'] {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    &.reduced {
        margin-left: -15px;
        margin-right: -15px;
        [class*='col-'] {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

//--------------

.emphasised {
    border-radius: 3px 3px 0px 0px;
    box-shadow: 0px 0px 30px 0px rgba(24,24,24,.05);
    padding: 60px 70px 100px;
}

//--------------

.sunk {
    border-bottom: 2px solid rgba(126, 126, 126, 0.15);
    box-shadow: 0 13px 50px -25px rgb(175 175 175 / 70%);
}

//--------------

.offset-pull {
    margin-top: -76px !important;
}

//--------------

.stretched {
    margin-left: -20px;
    margin-right: -20px;
}

//--------------

.module {
    overflow: hidden;
    position: relative;
    &.overflow {
        overflow: visible;
    }
    &.framed {
        padding: 30px;
    }
    &.banded {
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 260px;
            background-color: $lilac;
        }
    }
    &.pull-up {
        //z-index: 23;
        overflow: visible;
        //position: relative;
        margin-top: -200px;
        &.half {
            margin-top: -100px;
        }
        &.double {
            margin-top: -300px;
        }
    }
}

//--------------

.block {
    padding: 30px 25px;
    @include media-breakpoint-up(md) {
        padding: 50px 45px;
    }
    @include media-breakpoint-up(xl) {
        padding: 100px 90px;
    }
    &.shrink {
        @include media-breakpoint-up(md) {
            padding: 30px 45px 30px;
        }
        @include media-breakpoint-up(xl) {
            padding: 60px 90px 60px;
        }
    }
    &-limiter {
        margin: 0 auto;
        max-width: 1200px;
        position: relative;
        &.maxed {
            max-width: 1240px;
        }
        &.clipped {
            max-width: 1140px;
        }
        &.squeezed {
            max-width: 960px;
        }
        &.narrow {
            max-width: 690px;
        }
        &.is-pulled {
            margin-top: -200px;
            min-height: 200px;
            border-radius: 3px 3px 0px 0px;
            box-shadow: 0px 0px 30px 0px rgba(24,24,24,.05);
            padding: 60px 95px 100px;
            &.half {
                margin-top: -100px;
            }
            &.double {
                margin-top: -300px;
            }
            &.on-rentals {
                margin-top: -256px;
            }
            z-index: -1;
            z-index: 24;
        }
    }
    &-inner {
        &.narrow {
            margin: 0 auto;
            max-width: 690px;
        }
    }
    &-view-all {
        top: 0;
        right: 0;
        position: absolute;
    }
    &-title {
        line-height: 1;
        margin-bottom: 30px;
        //@include font-size(28);
        @include media-breakpoint-up(lg) {
            margin-bottom: 40px;
            //@include font-size(48);
        }
        &.reduced {
            @include font-size(20);
            @include media-breakpoint-up(lg) {
                @include font-size(40);
            }
        }
        &.lighten {
            font-family: 'GreycliffCF-DemiBold';
        }
    }
    &-subtitle {
        margin: 0 auto;
        max-width: 46rem;
        @include font-size(20);
    }
    &-top {
    }
    &-headings {
        margin-bottom: 60px;
        .block-title {
            margin-bottom: 25px;
            align-self: center;
        }
        .block-subtitle {
            margin-bottom: 0;
            font-weight: 300;
            @include font-size(17);
        }
    }
    &-buttons {
        font-size: 13px;
    }
    &-img {
        .img-shadow {
            box-shadow: 0px 0px 40px 0px rgba(82,63,105,0.25);
        }
    }
}

//--------------

.smile {
    position: relative;
    &:before {
        top: 0;
        bottom: 0;
        left: -12%;
        right: -12%;
        content: '';
        overflow: hidden;
        position: absolute;
        background-size: cover;
        background-color: $off-white;
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 0 0 150% 280%/0 0 1200px 2500px;
    }
}

//--------------

.faux-jumbo {
    &:before {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        background-size: auto;
        background-image: url('../img/content/hero/image-copyright-52-1.jpg');
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
    }
    &:after {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 6;
        background-color: rgba($charcoal, .65);
        position: absolute;
    }
    .container {
        z-index: 10;
        position: relative;
    }
}

//--------------

.has-overlay {
    position: relative;
    &:before {
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9;
        position: absolute;
        background: rgba($charcoal,.25);
    }
    &.lighten {
        &:before {
            background: rgba($muddy-green,.25);
        }
    }
    &.darken {
        &:before {
            background: rgba($charcoal,.5);
        }
    }
}

//--------------

.mb-40 {
    margin-bottom: 40px;
}
