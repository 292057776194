.top-nav {
    .menu {
        margin: 0;
        list-style: none;
        padding-left: 10px;
        padding-right: 10px;
        @include media-breakpoint-up(lg) {
            padding: 0;
            display: flex;
            justify-content: space-between;
        }
        li {
            position: relative;
            &:first-child {
                //margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            @include media-breakpoint-up(lg) {
                margin: 0 20px;
                display: inline-block;
            }
            a {
                display: block;
                color: $charcoal;
                @include font-size(22);
                &:not([href]) {
                    cursor: default;
                }
                @include media-breakpoint-up(lg) {
                    @include font-size(15);
                }
            }
            @include wp-active-menu-items() {
                @include before() {
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    height: 3px;
                    position: absolute;
                    background-color: $charcoal;
                }                
                a {
                    text-decoration: none;
                }
            }
            @include hover() {
                ul a,
                ul li ul li a {
                    font-size: 12px;
                    color: $turquoise;
                }
            }

            ul {
                z-index: 998; 
                padding: 10px;
                opacity: 0; 
                list-style: none;
                visibility: hidden; 
                position: absolute; 
                top: calc(100% + 0px); 
                left: calc(0% - 15px); 
                border-radius: 0 0 3px 3px; 
                background-clip: padding-box; 
                transition: opacity 0.3s ease; 
                border: 1px solid transparent; 
                background-color: rgba(52,52,52,1); 
                box-shadow: 0 2px 12px rgba(0, 0, 0, 0.175); 
                @include before() {
                    position: absolute; 
                    left: -1px; 
                    right: -1px; 
                    bottom: -6px; 
                    height: 6px; 
                    background-color: #006caa;
                    display: none;
                }
                @include after() {
                    position: absolute; 
                    left: -1px; 
                    right: -1px; 
                    top: -7px; 
                    height: 7px; 
                    background-color: transparent;
                    display: none;
                }
                li {
                    margin: 0; 
                    float: none;
                    border: none; 
                    min-width: 200px; 
                    position: relative;
                    line-height: normal; 
                    pointer-events: auto; 
                    &:last-child {
                        //border: none;
                    }
                }
                ul {
                    top: 0px; 
                    left: 100%;
                }
            }
            ul li,
            ul li ul li {
                @include wp-active-menu-items() {
                    a {
                        color: $off-white;
                        text-decoration: none;
                    }
                }
            }
            ul li a,
            ul li ul li a {
                display: block;
                padding: 10px 15px;
                &:before {
                    display: none !important;
                }
            }
            ul li:hover,
            ul li ul li:hover {
                
            }
            ul li:hover a,
            ul li ul li:hover a {
                color: $off-white;
            }
            &.menu-item-has-children:hover .sub-menu,
            &.menu-item-has-children .menu-item-has-children:hover .sub-menu {
                opacity: 1;
                visibility: visible;
                transition: ease-in 0.35s;
            }
            &.menu-item-has-children .menu-item-has-children .sub-menu {
                opacity: 0;
                visibility: hidden;                
            }
        }
    }
}


.menu-toggle {
    top: 20px;
    right: 20px;
    width: 52px;
    height: 52px;
    z-index: 15;
    display: block;
    position: absolute;
    text-align: center;
    background-color: #f1f1f1;
    @include border-radius(100%);
    @include media-breakpoint-up(sm) {
        top: 35px;
    }
    .bar {
        top: 18px;
        left: 16px;
        width: 20px;
        height: 1px;
        opacity: 1;
        position: absolute;
        transform-origin: 50% 50%;
        background-color: $primary;
        @include transition(background-color .3s linear, opacity .3s linear, transform .8s cubic-bezier(.86,0,.07,1));
        &:nth-child(2) {
            top: 26px;
            @include transition(background-color .3s linear, opacity .3s linear .2s, transform .8s cubic-bezier(.86,0,.07,1));
        }
        &:nth-child(3) {
            top: 34px;
        }
        body.off-canvas-open & {
            &:first-child {
                transform: translateY(8px) rotateZ(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
                @include transition(background-color .3s linear, opacity .3s linear, transform .8s cubic-bezier(.86,0,.07,1));
            }
            &:nth-child(3) {
                transform: translateY(-8px) rotateZ(-45deg);
            }
        }
        @include media-breakpoint-up(md) {
            left: 20px;
            top: 22px;
            &:nth-child(2) {
                top: 30px;
            }
            &:nth-child(3) {
                top: 38px;
            }            
        }
    }
    body.off-canvas-open & {
        background-color: $white;
    }
}
