// Colours
$primary: #19d3c5;
$secondary: #BEBCBD;
$dark-blue: #1f145d;
$charcoal: #282523;
$purple: #9C5FB5;
$dull-purple: #8a6ba3;
$lilac: #e1d8e6;
$off-white: #f0f2f2;
$nearly-white: #f9f9f9;
$nearly-black: #1D1D1B;
$turquoise: #19d3c5;
$muddy-green: #878787;
$yellow: #fed006;
$reddish: #fe3b1f;
$logo-grey: #3d3935;

$theme-colors: (
    'charcoal': $charcoal,
    'purple': $purple,
    'reddish': $reddish,
    'off-white': $off-white,
    'nearly-white': $nearly-white,
    'nearly-black': $nearly-black,
    'turquoise': $turquoise,
    'muddy-green': $muddy-green,
    'yellow': $yellow,
    'logo-grey': $logo-grey,
);

$colors: (
    'dark-blue': $dark-blue,
    'charcoal': $charcoal,
    'purple': $purple,
    'dull-purple': $dull-purple,
    'lilac': $lilac,
    'off-white': $off-white,
    'nearly-white': $nearly-white,
    'nearly-black': $nearly-black,
    'turquoise': $turquoise,
    'muddy-green': $muddy-green,
    'yellow': $yellow,
    'logo-grey': $logo-grey,
);

// Grid Breakpoints
$grid-breakpoints: (
   xs: 0,
   sm: 576px,
   md: 768px,
   lg: 992px,
   xl: 1200px,
  xxl: 1400px
);

// Grid Containers
$container-max-widths: (
     sm: 540px,
     md: 720px,
     lg: 960px,
     xl: 1140px,
    xxl: 1320px
);

$grid-gutter-width: 40px;

$spacer: 1rem;
$spacers: (
    6: ($spacer * 3.5),
    7: ($spacer * 4),
    8: ($spacer * 4.5),
    9: ($spacer * 5)
);

// Body
$body-color: $muddy-green;

// Links
$link-color: $turquoise;

// Font
$enable-responsive-font-sizes: true;
$font-size-base: 1rem;
$font-family-base: 'GreycliffCF-DemiBold', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-color-base: $muddy-green;
$font-weight-base: 400;

// Lead
$lead-font-weight: 400;

// Heading
$headings-font-family: 'GreycliffCF-Bold', sans-serif;
$headings-color: $logo-grey;

$display5-size: 3rem;
$display6-size: 2.5rem;

// Forms
$input-padding-y-lg: .75rem;
$input-padding-x-lg: 1.5rem;
$form-validation-input-shadow: false;
$custom-select-indicator-color: $body-color;

// Buttons
$input-btn-focus-box-shadow: none;

// Shadows
$enable-shadows: false;

// Modals
$modal-inner-padding: 2rem;
$modal-content-box-shadow-xs: 0px 0px 50px 0px rgb(82 63 105 / 15%);
$modal-content-box-shadow-sm-up: 0px 0px 50px 0px rgb(82 63 105 / 15%);

// Paths
$slick-font-path: '../webfonts/slick/';
$slick-loader-path: '../img/gif/';

// Buttons
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

//$btn-font-size: 18px;
//$btn-padding-y: 1rem;
//$btn-padding-x: 2rem;

$btn-font-size-lg: 1.175rem;
$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 2rem;

// Solid Form Variant
$input-solid-bg: #f8f8f8;
$input-solid-bg-focus: #f8f8f8;
$input-solid-placeholder-color: $body-color;
$input-solid-color: $body-color;

// Forms
//$custom-control-indicator-border-color: $input-solid-bg;
//$custom-control-indicator-focus-border-color: $input-solid-bg;
$component-active-bg: $turquoise;
$custom-select-indicator-color: $body-color;
